import React, { useState } from "react";
import styled, { css } from "styled-components";
import { FaLinkedinIn, FaFacebookF, FaInstagram } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const AboutCard = ({ data }) => {
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const handleNavigate = (location) => {
    navigate(location);
  };
  return (
    <Card
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => {
        handleNavigate(`/teamdetails/${data.id}`);
      }}
    >
      <img src={data.img} />
      <DataWrapper>
        <NameWrapper>{data.name}</NameWrapper>
        <PositionWrapper>{data.position}</PositionWrapper>
      </DataWrapper>
      <SocialsWrapper>
        <FaceBookIcon isHovered={isHovered} />

        <LinkedinIcon isHovered={isHovered} />

        <InstaIcon isHovered={isHovered} />
      </SocialsWrapper>
    </Card>
  );
};

export default AboutCard;

const Card = styled.a`
  width: 250px;
  height: 85%;
  border: 1px solid white;
  border-radius: 7px;
  margin-right: 10px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  background: black;

  img {
    width: 100%;
    height: 100%;
    transition: transform 0.8s;
    transform: scale(1.1);
  }
  &:hover {
    img {
      transform: scale(1.2);
    }
  }
`;
const DataWrapper = styled.div`
  width: 100%;
  height: 20%;
  bottom: 0;
  position: absolute;
  padding: 20px;
`;

const NameWrapper = styled.div`
  height: auto;
  width: 100%;
  color: white;
  font-size: ${({ theme }) => theme.fontSizes.title};
`;

const PositionWrapper = styled.div`
  color: white;
  font-family: ${({ theme }) => theme.fonts.heading};
  ${"" /* color: ${({ theme }) => theme.colors.brand.primary}; */}
`;

const SocialsWrapper = styled.div`
  position: absolute;
  width: 30px;
  height: 30%;
  left: 10px;
  top: 10%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  opacity: 0;
  transition: opacity 0.8s;
  ${Card}:hover & {
    opacity: 1;
  }
`;

const IconsStyle = css`
  width: 0px;
  height: 0px;
  color: white;
  transition: width 0.8s ease-in-out;
  transform-origin: center;

  ${({ isHovered }) =>
    isHovered &&
    css`
      width: 20px;
      height: 20px;
    `}
  &:hover {
    color: ${({ theme }) => theme.colors.ui.button};
  }
`;

const FaceBookIcon = styled(FaFacebookF)`
  ${IconsStyle};
`;

const InstaIcon = styled(FaInstagram)`
  ${IconsStyle};
`;

const LinkedinIcon = styled(FaLinkedinIn)`
  ${IconsStyle};
`;
