import mycinema from "../../assets/images/mycinema.jpg";
import hairsaloon from "../../assets/images/hairsaloon.jpg";
import cafe from "../../assets/images/cafe.jpg";
import theatreclass from "../../assets/images/theatreclass.jpg";
import actionHeader from "../../assets/images/Mockups/actiontheatre-mockup3.jpg";
import actionBody from "../../assets/images/Mockups/actionbody.jpg";
import actionmockup from "../../assets/images/Mockups/actiontheatre-mockup2.jpg";
import actiondescr from "../../assets/images/Mockups/actiontheatre-mockup.jpg";
import essenza from "../../assets/images/essenza.jpg";
import essenzaHeader from "../../assets/images/Mockups/essenza-mockup.jpg";
import essenzaBody from "../../assets/images/Mockups/essenzabody.jpg";
import essenzamockup from "../../assets/images/Mockups/socialmedia-mockup.jpg";
import essenzadescr from "../../assets/images/Mockups/essenza-gif.jpg";
import wrappamondo from "../../assets/images/projects/wrappamondo.jpg";
import wrappamondoHeader from "../../assets/images/Mockups/wrappamondoPaperReal.jpg";
import wrappamondoBody from "../../assets/images/Mockups/wrappamondoBody.jpg";
import wrappamondomockup from "../../assets/images/Mockups/wrappamondowall2.jpg";
import wrappamondodescr from "../../assets/images/Mockups/wrappamondoPaper2.jpg";
import seventhHeader from "../../assets/images/Mockups/seventhheaven-mockup.jpg";
import seventhBody from "../../assets/images/projects/seventhheaven.jpg";
import seventhmockup from "../../assets/images/Mockups/seventhheaven-mockup3.jpg";
import seventhheavendescr from "../../assets/images/Mockups/seventhheaven-mockup2.jpg";
import hairHeader from "../../assets/images/projects/iris-background.jpg";
import hairBody from "../../assets/images/projects/irishair.jpg";
import hairMockup from "../../assets/images/projects/iris-hair2.jpg";
import hairDescr from "../../assets/images/projects/iris-hair.jpg";
import MycinemaMock from "../../assets/images/Mockups/mycinema-mockup.jpg";
import mycinemaHeader from "../../assets/images/Mockups/mycinema-mockupheader.jpg";
import mycinemaBody from "../../assets/images/projects/Mycinema-header1.jpg";
import mycinemamockup from "../../assets/images/Mockups/mycinema-mockupmobiles.jpg";
import mycinemadescr from "../../assets/images/Mockups/mycinema-mockup2.jpg";
import Mycinema2 from "../../assets/images/projects/mycinema-neon.jpg";
import Mycinema3 from "../../assets/images/projects/mycinema-neon2.jpg";
import Mycinema4 from "../../assets/images/projects/mycinema-neon3.jpg";
import MycinemaHeader from "../../assets/images/projects/Mycinema-header1.jpg";
import MycinemaScreenshot from "../../assets/images/projects/mycinema-screenshots-collection.jpg";
import MycinemaLogo from "../../assets/images/logos/MycinemaLogo.png";

export const projects = [
  {
    description: "Movies and Series search engine.",
    tech: "API and Data management",
    name: "MyCinema",
    link: "https://mycinema-omega.vercel.app/",
    img: mycinema,
    img2: mycinemaHeader,
    img3: mycinemaBody,
    img4: mycinemamockup,
    img5: mycinemadescr,
    screenshot: MycinemaScreenshot,
    logo: MycinemaLogo,
    alt: "mycinemaImage",
    job: "Web Design, Implementation",
    design: 2,
    mockTheme: "dark",
    infoHeader: "api and data management",
    info: "Mycinema is a user-friendly web app designed for movie and series enthusiasts, offering a comprehensive database of information about actors, movies, and TV series. Users can create an account, granting them access to a wealth of information, and enabling them to create a 'watched list.' This feature allows users to curate a personalized collection of movies and series they've already seen, complete with the ability to rate and review their selections. Mycinema goes beyond mere information, letting users plan their future watchlist with ease. They can add movies and series they intend to watch and then organize these selections using drag-and-drop functionality. Mycinema is a complete movie and series management tool, simplifying the tracking of watched content and planning for future viewing experiences.",
    info2:
      " Mycinema was meticulously crafted with a user-centric approach at its core, ensuring that it caters to the precise needs of movie and series enthusiasts. Every aspect of Mycinema, from its blazing speed to the optimized information delivery, has been fine-tuned to provide an exceptional user experience. It's important to note that all the data provided by Mycinema is sourced from The Movie Database (TMDb), and the platform is not intended for profit-driven purposes. Our primary aim is to serve the community of cinephiles and TV series aficionados by offering a rich, reliable, and user-friendly resource for their entertainment needs.",
  },
  {
    description: "Web Design and Online Booking engine.",
    tech: "Web design & booking engine",
    name: "Iris Hair",
    link: "https://irishair-aiw4poevj-shuyin21.vercel.app/",
    img: hairsaloon,
    img2: hairHeader,
    img3: hairBody,
    img4: hairMockup,
    img5: hairDescr,
    alt: "harisaloonImage",
    job: "Web Design, Implementation",
    design: 2,
    // headerImg1: IrisHeader2,
    // mockImage: IrisHairMock,
    mockTheme: "light",
    infoHeader: "Write a header about Iris Hair webpage",
    info: "Iris Hair is the go-to hair salon for those seeking the ultimate in hair services. They are renowned for their commitment to delivering exceptional experiences, providing expert haircuts, precise hair coloring, and a range of other professional services. With a team of highly trained and experienced hairdressers, Iris Hair ensures that clients receive the best care, using top-quality products in the industry. Clients can place their trust in Iris Hair for outstanding results and a refreshed look that leaves them feeling their absolute best.",
    info2:
      "We developed the Iris Hair website with their customers in mind, aiming to showcase the salon's exceptional work and make their services easily accessible. Our primary goal was to offer transparency in pricing and provide a convenient way for customers to get in touch with the salon. The website serves as a digital portfolio, where potential clients can explore salon's hair services and see the quality of the salon work firsthand. To further enhance the customer experience, we've integrated a seamless booking service directly on the website. This feature allows customers to schedule their appointments effortlessly, ensuring a hassle-free and efficient way to access our top-notch services.",
  },
  {
    description: "Web Design and Online Booking engine.",
    tech: "Web design & booking engine",
    name: "Seventh Heaven",
    img: cafe,
    img2: seventhHeader,
    img3: seventhBody,
    img4: seventhmockup,
    img5: seventhheavendescr,
    alt: "cafeImage",
    job: "Web Design, Implementation",
    // headerImg1: IrisHeader2,
    design: 2,
    // mockImage: IrisHairMock,
    mockTheme: "light",
    infoHeader: "Write a header about the webpage",
    info: "Seventh Heaven is your go-to culinary destination for an exceptional dining experience. Renowned for their unwavering commitment to delivering exquisite flavors, their talented chefs craft delectable dishes that cater to every palate. From appetizers to desserts, Seventh Heaven uses the finest ingredients for visually appealing and flavorful creations. Their warm ambiance, attentive staff, and dedication to service ensure a satisfying and memorable dining experience. Trust Seventh Heaven for outstanding cuisine that leaves you craving more, and discover a taste of heaven in every bite.",
    info2:
      "We designed the Seventh Heaven website with their patrons at the forefront, aiming to showcase their culinary excellence and offer convenient accessibility. Our primary objective was to provide transparency in menu and pricing, making it effortless for guests to explore the offerings and get in touch with them. The website serves as a digital window into Seventh Heaven's culinary world, where potential diners can peruse the delightful menu options and witness the quality of their dishes firsthand.",
  },
  {
    description: "Class book engine.",
    tech: "Booking engine",
    name: "Theatre Club",
    link: "https://mycinema-omega.vercel.app/",
    img: theatreclass,
    img2: actionHeader,
    img3: actionBody,
    img4: actionmockup,
    img5: actiondescr,
    alt: "TheatreImage",
    job: "Web Design, Implementation",
    headerImg1: MycinemaMock,
    design: 2,
    mockImage: MycinemaMock,
    mockTheme: "dark",
    infoHeader: "Write a header about the webpage",
    info: "Theatre Club is the premier hub for young talents, offering top-notch performing arts education. Their seasoned professionals guide children of all ages in acting, singing, and dancing. Beyond traditional classes, they foster creativity, build confidence, and create a supportive community. Parents trust their expert instructors for industry-leading methods and a nurturing environment. Join Theatre Club to witness your child's confident transformation into a skilled performer, ready to shine on stage with pride.",
    info2:
      "With a customer-centric approach, we crafted the Theatre Club website to seamlessly connect with their audience. The objective was to showcase the breadth of their offerings, including holiday workshops, classes, and free trials, while ensuring easy accessibility and clear communication. The website functions as a comprehensive digital hub, providing a transparent view of their diverse programs and locations. Designed as a user-friendly digital portfolio, the website allows prospective participants to explore the dynamic world of Theatre Club. Emphasizing transparency, we have listed all holiday workshops, classes, and free trial sessions, empowering visitors to make informed choices.",
  },
  {
    description: "Branding and sales engine.",
    tech: "Branding, eCommerce",
    name: "Essenza",
    link: "https://mycinema-omega.vercel.app/",
    img: essenza,
    img2: essenzaHeader,
    img3: essenzaBody,
    img4: essenzamockup,
    img5: essenzadescr,
    alt: "TheatreImage",
    job: "Web Design, Implementation",
    headerImg1: MycinemaMock,
    design: 2,
    mockImage: MycinemaMock,
    mockTheme: "dark",
    infoHeader: "Write a header about the webpage",
    info: "At Essenza, the company has perfected the art of candle crafting, where every step of the process is infused with meticulous attention to detail and an unwavering commitment to quality. Essenza's candles are meticulously hand-made by skilled artisans, who employ their expertise to create not just products but works of art that epitomize excellence. Essenza takes great pride in their careful curation of premium ingredients, meticulously sourced and blended to perfection. Each candle embodies a harmonious union of the finest raw materials, ensuring that when they're lit, the result is an olfactory symphony that transcends the ordinary. The scent that emanates from Essenza's candles is not just delightful; it's an experience that envelops the space in a soothing, aromatic embrace.",
    info2:
      "The Essenza brand's unique visual identity is a product of extensive research and a wealth of design expertise. In a deliberate and thoughtful approach, we have integrated the art of hand-style drawing into our design philosophy, imbuing our products with a charming and personal touch. Additionally, our deliberate choice of a palette featuring a harmonious selection of light colors speaks to our commitment to creating a soothing and visually pleasing environment. This combination of creative expression and a meticulous approach to color selection underscores the essence of the Essenza brand, ensuring that our products not only stand out but also engage the senses in a truly unique and delightful way.",
  },
  {
    description: "Branding and marketing.",
    tech: "Branding",
    name: "Wrappamondo",
    link: "https://mycinema-omega.vercel.app/",
    img: wrappamondo,
    img2: wrappamondoHeader,
    img3: wrappamondoBody,
    img4: wrappamondomockup,
    img5: wrappamondodescr,
    alt: "Wrappamondo Business card",
    job: "Web Design, Implementation",
    headerImg1: MycinemaMock,
    design: 2,
    mockImage: MycinemaMock,
    mockTheme: "dark",
    infoHeader: "Write a header about the webpage",
    info: "Step into a realm of adhesive artistry with Wrappamondo, where precision meets innovation in every self-adhesive creation. Their skilled artisans meticulously transform surfaces into expressions of sophistication, using premium materials curated for excellence. Whether on cars, walls, or windows, Wrappamondo's films go beyond functionality, crafting an immersive experience that elevates spaces with seamless style and captivating allure. Welcome to the world where self-adhesive meets sophistication.",
    info2:
      "Embark on a visual journey with Wrappamondo's distinctive identity, meticulously crafted through extensive design exploration. The logo seamlessly intertwines artistic elements, adding a personalized charm that distinctly represents self-adhesive films. A carefully selected color palette, featuring a blend of sleek and modern hues, emphasizes a dedication to creating an aesthetically pleasing atmosphere, reminiscent of the innovative nature of their adhesive products. This fusion of creative expression and precise color curation defines the essence of the design, ensuring the logo not only captures attention but also immediately evokes the world of Wrappamondo's cutting-edge self-adhesive films. Immerse yourself in a visual experience that resonates with the core of their commitment to quality and innovation.",
  },
];
