import React from "react";
import styled from "styled-components";
import PrivacyComponent from "../components/termsandprivacy/privacy.component";

const Privacy = () => {
  return (
    <PrivacyWrapper>
      <PrivacyInsideWrapper>
        <PrivacyComponent />
      </PrivacyInsideWrapper>
    </PrivacyWrapper>
  );
};

export default Privacy;

const PrivacyWrapper = styled.div`
  min-height: 600px;
  ${({ theme }) => theme.pageSizes.media};
`;

const PrivacyInsideWrapper = styled.div`
  width: 100%;
  padding: 120px 0;
  min-height: 600px;
  background: ${({ theme }) => theme.colors.proposal.contact};
  ${({ theme }) => theme.flexing.columnCenter};
`;
