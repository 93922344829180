import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const PrivacySections = ({ data }) => {
  return (
    <SectionWrapper>
      {data.map((section, index) => (
        <div key={index}>
          {section.title && <Title>{section.title}</Title>}
          {section.p && (
            <Par>
              {section.p}
              {section.a && <Link to="/terms">{section.a}</Link>}
            </Par>
          )}
          {section.header && <Header>{section.header}</Header>}

          {section.ordered && (
            <Ordered>
              {section.ordered.map((item, idx) => (
                <li key={idx}>{item}</li>
              ))}
            </Ordered>
          )}
          {section.ol && (
            <Ordered>
              {section.ol.map((subSection, subIndex) => (
                <li key={subIndex}>
                  {subSection.p && <Par>{subSection.p}</Par>}
                  {subSection.table && (
                    <Table>
                      <thead>
                        <tr>
                          <th key={subSection.tableHeader}>
                            {subSection.tableHeader}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {subSection.table.map((row, rowIndex) => (
                          <tr key={rowIndex}>
                            <td>
                              <strong key={row.tableHeader}>
                                {row.tableHeader}
                              </strong>
                              {row.tableP && <Par>{row.tableP}</Par>}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )}
                  {subSection.p2 && (
                    <div>
                      <p>{subSection.p2}</p>
                      {subSection.ol && (
                        <ol>
                          {subSection.ol.map((item, itemIndex) => (
                            <li key={itemIndex}>{item}</li>
                          ))}
                        </ol>
                      )}
                      <p>{subSection.span}</p>
                    </div>
                  )}
                </li>
              ))}
            </Ordered>
          )}
        </div>
      ))}
    </SectionWrapper>
  );
};

const SectionWrapper = styled.div`
  margin: 0 10%;
  a {
    text-decoration: none;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    color: ${({ theme }) => theme.colors.text.gndtfrom};
  }
`;

const Title = styled.h1`
  font-size: ${({ theme }) => theme.fontSizes.h3};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  @media screen and (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSizes.h4};
    text-align: left;
  }
`;

const Header = styled.h4`
  font-size: ${({ theme }) => theme.fontSizes.body};
`;

const Par = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.body};
  white-space: pre-line;
  @media screen and (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSizes.button};
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin: 1em 0;
  th {
    text-align: left;
  }
  td {
    padding: 0.5em 1em;
    border: 1px solid #ccc;
  }
`;

const Ordered = styled.ol`
  padding-left: 1em;
  margin-left: 1em;
  li {
    margin: 0.5em 0;
    @media screen and (max-width: 768px) {
      font-size: ${({ theme }) => theme.fontSizes.button};
    }
  }
`;

export default PrivacySections;
