import { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import isPropValid from "@emotion/is-prop-valid";
import { styled, StyleSheetManager, ThemeProvider } from "styled-components";
import { theme } from "./styling/theme";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home.page";
import MockPage from "./pages/MockPage";
import Navbar from "./components/navbar/nav.component";
import Footer from "./components/footer/footer";
import Expertise from "./pages/Expertise.page";
import ServicesPage from "./pages/Services.page";
import Work from "./pages/Work.page";
import About from "./pages/About.page";
import Contact from "./pages/Contact.page";
import Terms from "./pages/Terms.page";
import Privacy from "./pages/Privacy.page";
import ScrollToTop from "./ScrollToTop";
import { ColorProvider } from "./context/color.context";
import { MessageProvider } from "./context/message.context";
import CookieConsent from "./components/cookieconsent/CookieConsent";
import ProjectPage from "./pages/Project.page";
import TeamDetailsPage from "./pages/TeamDetails.page";
import ServiceDetailedPage from "./pages/Service.Detailed";
import CookiesCustomize from "./components/cookieconsent/CookiesCustomize";

function App() {
  const [cookies] = useCookies(["cookieConsent"]);
  const [background, setBackground] = useState("#000");
  const [linked, setLinked] = useState(false);
  const backgroundCatcher = (newBackground) => {
    setBackground(newBackground);
  };
  useEffect(() => {
    // Set isLoaded to true when location.pathname changes
    setLinked(true);

    // Set Linked back to false after 2 seconds
    const timer = setTimeout(() => {
      setLinked(false);
    }, 2000);

    // Clear the timer if the component unmounts or location.pathname changes
    return () => clearTimeout(timer);
  }, [background]);

  useEffect(() => {
    console.log(window.location.pathname);
  }, [window.location.pathname]);
  return (
    <ThemeProvider theme={theme}>
      <StyleSheetManager shouldForwardProp={isPropValid}>
        <ColorProvider>
          <MessageProvider>
            <Router>
              {/* <TestLoading /> */}
              <AllWrapper background={background} linked={linked}>
                {window.location.pathname != "/privacyReadOnly" && (
                  <Navbar backgroundCatcher={backgroundCatcher} />
                )}
                <ScrollToTop />
                {/* <LoadingScreen /> */}
                {!localStorage.getItem("cookie-preferences") && (
                  <CookiesCustomize />
                )}
                <Routes>
                  <Route exact path="/" element={<Home />} />
                  <Route exact path="/home" element={<Home />} />
                  {/* <Route exact path="/expertise" element={<Expertise />} /> */}
                  <Route exact path="/expertise" element={<ServicesPage />} />
                  <Route
                    exact
                    path="/expertise/:serviceId"
                    element={<ServiceDetailedPage />}
                  />
                  <Route exact path="/about" element={<About />} />
                  <Route exact path="/work" element={<Work />} />
                  <Route exact path="/contact" element={<Contact />} />
                  <Route exact path="/terms" element={<Terms />} />
                  <Route exact path="/privacy" element={<Privacy />} />
                  <Route exact path="/privacyReadOnly" element={<Privacy />} />

                  <Route
                    exact
                    path="/teamdetails/:teamId"
                    element={<TeamDetailsPage />}
                  />
                  <Route
                    exact
                    path="/project/:projectId"
                    element={<ProjectPage />}
                  />
                </Routes>
              </AllWrapper>
              {window.location.pathname != "/privacyReadOnly" && <Footer />}
            </Router>
          </MessageProvider>
        </ColorProvider>
      </StyleSheetManager>
    </ThemeProvider>
  );
}

export default App;

const AllWrapper = styled.div`
  font-family: ${({ theme }) => theme.fonts.header};
  background: ${({ background }) => background};
  transition: background 2s ease;
`;
