import React, { useState, useEffect, useCallback, useContext } from "react";
import isPropValid from "@emotion/is-prop-valid";
import { styled, StyleSheetManager } from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";
import { RxHamburgerMenu } from "react-icons/rx";
import { IoMdClose } from "react-icons/io";
import Link from "./nav.link";
import logofades from "../../assets/images/logofadeswhite.png";
import ColorContext from "../../context/color.context";

const Navbar = ({ backgroundCatcher }) => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState();
  const [hambOpen, setHambOpen] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);
  const location = useLocation();

  const { changeBackgroundColor, background, mobileBackground } =
    useContext(ColorContext);

  const navigate = useNavigate();

  const hamBarCloser = () => {
    setHambOpen(false);
  };
  const handleWindowResize = useCallback((event) => {
    setIsMobile(window.innerWidth < 821 ? true : false);
    setWindowSize(window.innerWidth);
  }, []);

  const HandleClick = () => {
    navigate("/home");
    setHambOpen(false);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [handleWindowResize]);

  useEffect(() => {
    const changedBcg = changeBackgroundColor(location.pathname);
    backgroundCatcher(changedBcg);
  }, [location.pathname]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setHasScrolled(true);
      } else {
        setHasScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  if (windowSize > 830) {
    return (
      <StyleSheetManager shouldForwardProp={isPropValid}>
        <NavWrap background={background} scrolled={hasScrolled}>
          <Logo onClick={() => navigate("/home")} src={logofades} alt="logo" />
          <LinksWrap>
            <Link path="/work" name="Work" />
            <Link path="/expertise" name="Services" />
            <Link path="/about" name="About" />
            <Link path="/contact" name="Contact Us" />
          </LinksWrap>
        </NavWrap>
      </StyleSheetManager>
    );
  }

  return (
    <StyleSheetManager shouldForwardProp={isPropValid}>
      <NavWrap background={background}>
        <Logo onClick={() => HandleClick()} src={logofades} alt="logo" />
        {!hambOpen ? (
          <>
            <HamMenuIcon onClick={() => setHambOpen(!hambOpen)} />
          </>
        ) : (
          <CloseMenuIcon onClick={() => setHambOpen(!hambOpen)} />
        )}
        {hambOpen ? (
          <NavbarMobile background={mobileBackground}>
            <Link
              hamBarCloser={hamBarCloser}
              isMobile={true}
              path="/work"
              name="Work"
            />
            <Link
              hamBarCloser={hamBarCloser}
              isMobile={true}
              path="/expertise"
              name="Services"
            />
            <Link
              hamBarCloser={hamBarCloser}
              isMobile={true}
              path="/about"
              name="About"
            />
            <Link
              hamBarCloser={hamBarCloser}
              isMobile={true}
              path="/contact"
              name="Contact Us"
            />
          </NavbarMobile>
        ) : (
          <></>
        )}
      </NavWrap>
    </StyleSheetManager>
  );
};

export default Navbar;

const NavWrap = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  padding: 25px 0;
  ${({ theme }) => theme.flexing.flexCenter};
  height: ${({ theme }) => theme.sizes[3]};
  background: ${({ background, scrolled }) =>
    scrolled ? background : "transparent"};
  transition: background 1s ease;
  z-index: 999;

  @media screen and (max-width: 1000px) {
    background-color: ${({ theme }) => theme.colors.ui.black};
  }
`;

const HamMenuIcon = styled(RxHamburgerMenu)`
  color: ${({ theme }) => theme.colors.text.inverse};
  margin-right: 15px;
  font-size: ${({ theme }) => theme.fontSizes.h4};
  position: absolute;
  right: 20px;
`;

const CloseMenuIcon = styled(IoMdClose)`
  color: ${({ theme }) => theme.colors.text.inverse};
  margin-right: 15px;
  font-size: ${({ theme }) => theme.fontSizes.h4};
  position: absolute;
  right: 20px;
`;

const Logo = styled.img`
  width: 7rem;
  position: absolute;
  left: 30px;
  cursor: pointer;
  padding-left: 15px;
`;

const LinksWrap = styled.div`
  width: 50%;
  display: flex;
  position: absolute;
  right: 40px;
  justify-content: flex-end;
`;

// Mobile CSS

const NavbarMobile = styled.div`
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.9);
  position: absolute;

  ${({ theme }) => theme.flexing.columnCenter};
  z-index: 1;
  top: 6rem;
`;
