export const flexing = {
  columnCenter:
    "display:flex;flex-direction:column; align-items:center;justify-content:center;",
  flexCenter:
    "display:flex;flex-direction:row; align-items:center;justify-content:center;",
  flexTopCenter:
    "display:flex;flex-direction:row; align-items:flex-start;justify-content:center;",
};

// ${({ theme }) => theme.flexing.columnCenter};
//${({ theme }) => theme.flexing.flexCenter};
