import React from "react";
import styled from "styled-components";

const ProjectHeaderDesign1 = ({ data }) => {
  return (
    <MainWrapper>
      <ImageWrapper>
        <HeaderWrapper>
          <ClipContainer />
        </HeaderWrapper>
        <LeftBox>
          <HeaderContainer>
            <Title>{data.name}</Title>
            <Info1>{data.description}</Info1>
            <Info2>{data.infoHeader}</Info2>
            <Logo>{data.name}</Logo>
          </HeaderContainer>
        </LeftBox>
        <RightBox>
          <Image src={data.img5} />
          <Image2 src={data.img4} />
        </RightBox>
      </ImageWrapper>
    </MainWrapper>
  );
};

export default ProjectHeaderDesign1;

const MainWrapper = styled.div`
  width: 100%;
  height: 600px;
  overflow: hidden;
  background: white;
  margin: 20px 0;
  @media screen and (max-width: 1100px) {
    height: 500px;
  }
  @media screen and (max-width: 768px) {
    height: 400px;
  }
  @media screen and (max-width: 500px) {
    height: 300px;
  }
  @media screen and (max-width: 400px) {
    height: 250px;
  }
`;
const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
`;
const LeftBox = styled.div`
  flex: 0.8;
  height: 100%;
  overflow: hidden;
  background: #000;
  ${({ theme }) => theme.flexing.flexCenter};
  @media screen and (max-width: 1150px) {
    display: none;
  }
`;
const RightBox = styled.div`
  flex: 1;
  height: 100%;
  margin-left: 100px;
  overflow: hidden;
  @media screen and (max-width: 1150px) {
    margin-left: 0;
  }
`;
const Image = styled.img`
  max-height: 100%;
  @media screen and (max-width: 1150px) {
    width: 100%;
    height: 100%;
  }
  @media screen and (max-width: 769px) {
    display: none;
  }
`;
const Image2 = styled.img`
  width: 100%;
  height: 100%;
  @media screen and (min-width: 769px) {
    display: none;
  }
`;
const HeaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  left: 20%;
  @media screen and (max-width: 1150px) {
    display: none;
  }
`;

const HeaderContainer = styled.div`
  position: absolute;
  max-width: 350px;
  height: 200px;

  display: flex;
  flex-direction: column;
  text-align: left;
  z-index: 1;
  @media screen and (max-width: 1100px) {
    text-align: left;
    padding-left: 10px;
  }
`;

const ClipContainer = styled.div`
  width: 40%;
  height: 100%;
  background: #000;

  webkit-clip-path: polygon(
    75% 0%,
    100% 50%,
    75% 100%,
    0% 100%,
    25% 50%,
    0% 0%
  );
  clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%);
`;

const Title = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.h3};
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fonts.header};
  margin-bottom: 20px;
  color: #fff;
`;
const Info1 = styled.div`
  font-family: ${({ theme }) => theme.fonts.heading};
  font-weight: bold;
  color: #fff;
`;
const Info2 = styled.div`
  font-family: ${({ theme }) => theme.fonts.heading};
  color: ${({ theme }) => theme.colors.text.secondary};
  text-transform: uppercase;
`;
const Logo = styled.div`
  margin-top: 10px;
  color: ${({ theme }) => theme.colors.text.secondary};
`;
