import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { projects } from "../components/projectComponents/ProjectsData";
import ProjectDesign1 from "../components/projectPageComponents/projectdesign1Component/ProjectDesign1";
import Projectdesign2 from "../components/projectPageComponents/projectdesign2component/Projectdesign2";

const ProjectPage = () => {
  const { projectId } = useParams();
  const [prjData, setPrjData] = useState([]);
  useEffect(() => {
    const project = projects.find((prj) => prj.name === projectId);

    if (project) {
      setPrjData(project);
    }
  }, [projectId]);

  return (
    <ProjectWrapper>
      <ProjectInsideWrapper>
        {prjData.design === 1 ? (
          <ProjectDesign1 data={prjData} />
        ) : (
          <Projectdesign2
            data={prjData}
            img2={prjData.img2}
            img3={prjData.img3}
            img4={prjData.img4}
            img5={prjData.img5}
            title={prjData.name}
            info={prjData.info}
            info2={prjData.info2}
          />
        )}
      </ProjectInsideWrapper>
    </ProjectWrapper>
  );
};

export default ProjectPage;

const ProjectWrapper = styled.div`
  min-height: 700px;
  /* ${({ theme }) => theme.pageSizes.media}; */

  @media screen and (max-width: 1000px) {
    margin-top: 70px;
  }
`;

const ProjectInsideWrapper = styled.div`
  width: 100%;
  min-height: 600px;
  background: #000000;
  color: #fff;
  ${({ theme }) => theme.flexing.columnCenter};
`;
