import React from "react";
import styled from "styled-components";
import TermsAndConditions from "../components/termsandprivacy/terms.component";

const Terms = () => {
  return (
    <TermsWrapper>
      <TermsInsideWrapper>
        <TermsAndConditions />
      </TermsInsideWrapper>
    </TermsWrapper>
  );
};

export default Terms;

const TermsWrapper = styled.div`
  min-height: 600px;
  ${({ theme }) => theme.pageSizes.media};
`;

const TermsInsideWrapper = styled.div`
  width: 100%;
  min-height: 600px;
  background: ${({ theme }) => theme.colors.proposal.contact};
  padding: 120px 0;
`;
