import React from "react";
import styled, { keyframes, css } from "styled-components";
import background from "../../assets/images/homeBackground.jpg";
import background2 from "../../assets/images/homeBackground-2.jpg";
import { useNavigate } from "react-router-dom";
import { BsArrowRightShort } from "react-icons/bs";
import Button from "../button/Button";

const HomeBackground = () => {
  const navigate = useNavigate();

  const handleNavigate = (path) => {
    console.log("working");
    navigate(path);
  };

  return (
    <MainWrap>
      <BackgroundImageOne alt="background" />
      <BackgroundImageTwo alt="background" />

      <HomeWrap>
        <TextWrap>
          <MainText>Empowering Your Development Pathway</MainText>
          <Text>
            We specialise in developing custom websites, mobile apps, digital
            products, and bespoke software solutions.
          </Text>
          <ButtonWrap onClick={() => handleNavigate("/expertise")}>
            <Button text={"What we do"} buttonTheme={"dark"} />
          </ButtonWrap>
        </TextWrap>
      </HomeWrap>
    </MainWrap>
  );
};

export default HomeBackground;

const MainWrap = styled.div`
  width: 100%;
  height: auto;
  background: ${({ theme }) => theme.colors.ui.black};
  position: relative;
  overflow: hidden;
  ${({ theme }) => theme.flexing.columnCenter};

  @media screen and (max-width: 768px) {
    margin-top: 90px;
    height: 70vh;
  }

  @media screen and (min-width: 800px) and (max-width: 1200px) {
    margin-top: 90px;
    height: 70vh;
  }
`;

const darkenOverlay = css`
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4); /* 50% opaque black */
`;

const fadeInOut = keyframes`
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
`;

const BackgroundImageOne = styled.div`
  animation: ${fadeInOut} 15s infinite;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-image: url(${background});
  background-size: cover;
  background-position: center;
  opacity: 0.4;
  &::before {
    ${darkenOverlay}
  }
  @media screen and (max-width: 1000px) {
    height: 100%;
  }
`;

const BackgroundImageTwo = styled.div`
  animation: ${fadeInOut} 15s infinite;
  animation-delay: 10s;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-image: url(${background2});
  background-size: cover;
  background-position: center;
  opacity: 0.4;
  &::before {
    ${darkenOverlay}
  }
  @media screen and (max-width: 1000px) {
    height: 100%;
  }
`;

const HomeWrap = styled.div`
  ${({ theme }) => theme.pageSizes.mediaInsideSize};
  ${({ theme }) => theme.flexing.flexCenter};
  width: 100%;
  height: 100%;
  position: absolute;
`;

const slideFromLeft = keyframes`
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const slideFromRight = keyframes`
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const slideFromBottom = keyframes`
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const TextWrap = styled.div`
  animation: ${slideFromLeft} 1s forwards;
  @media screen and (max-width: 768px) {
    width: 90%;
  }
`;

const MainText = styled.h1`
  width: 80%;
  font-size: 3.8rem;
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-family: ${({ theme }) => theme.fonts.header};
  color: ${({ theme }) => theme.colors.text.inverse};
  animation: ${slideFromLeft} 1s forwards;
  @media screen and (min-width: 768px) and (max-width: 1000px) {
    font-size: ${({ theme }) => theme.fontSizes.h3};
  }

  @media screen and (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSizes.mobileHeader};
  }
`;

const Text = styled.h1`
  width: 95%;
  animation: ${slideFromLeft} 1s forwards;
  color: ${({ theme }) => theme.colors.text.inverse};
  font-size: ${({ theme }) => theme.fontSizes.title};
  font-family: ${({ theme }) => theme.fonts.heading};

  @media screen and (min-width: 800px) and (max-width: 900px) {
    font-size: ${({ theme }) => theme.fontSizes.title};
  }

  @media screen and (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSizes.body};
  }
`;

const ButtonWrap = styled.div`
  width: 220px;
  animation: ${slideFromLeft} 1.5s forwards;
`;
