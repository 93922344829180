import React from "react";
import styled from "styled-components";

const ProjectDesign1Brief = ({ data }) => {
  return (
    <MainDiv>
      <Container>
        <TopBox>
          <Title>The Brief</Title>
          <InfoWrapper>{data.info}</InfoWrapper>
        </TopBox>
        <BottomBox>
          <ImageWrapper>
            <Image src={data.img2} />
            <ImageAbsolute src={data.img3} />
          </ImageWrapper>
          <HeaderContainer>
            <Name>{data.name}</Name>
            <Info1>{data.description}</Info1>
            <Info2>{data.infoHeader}</Info2>
            {data.logo && <Logo src={data.logo} />}
          </HeaderContainer>
        </BottomBox>
      </Container>
      <InfoWrapper2>{data.info}</InfoWrapper2>
    </MainDiv>
  );
};

export default ProjectDesign1Brief;

const MainDiv = styled.div`
  width: 100%;
  min-height: 200px;
  color: #000;
  ${({ theme }) => theme.flexing.columnCenter};
  ${({ theme }) => theme.pageSizes.mediaInsideSize};
`;

const Container = styled.div`
  width: 100%;
  height: 800px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    max-width: 90%;
  }
`;
const TopBox = styled.div`
  flex: 0.4;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const BottomBox = styled.div`
  flex: 0.6;
  display: flex;
  position: relative;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;
const Title = styled.div`
  width: 100%;
  font-size: ${({ theme }) => theme.fontSizes.h5};
  text-transform: uppercase;
  color: #666;
  @media screen and (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSizes.title};
  }
`;

const InfoWrapper = styled.div`
  width: 60%;
  margin-top: 20px;
  height: auto;
  font-family: ${({ theme }) => theme.fonts.heading};
  color: #666;
  @media screen and (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSizes.button};
    width: 100%;
  }
`;

const InfoWrapper2 = styled.div`
  width: 100%;
  margin-top: 20px;
  height: auto;
  text-align: left;
  font-family: ${({ theme }) => theme.fonts.heading};
  color: #666;
  @media screen and (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSizes.button};
    width: 90%;
  }
`;
const ImageWrapper = styled.div`
  flex: 0.5;
  @media screen and (max-width: 768px) {
    text-align: left;
  }
`;
const Image = styled.img`
  width: 550px;
  height: 300px;
  border-radius: 7px;
  @media screen and (max-width: 1200px) {
    width: 450px;
  }
  @media screen and (max-width: 990px) {
    width: 400px;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  @media screen and (max-width: 500px) {
    height: 250px;
  }
`;
const ImageAbsolute = styled.img`
  width: 550px;
  height: 300px;
  border-radius: 7px;
  position: absolute;
  right: 150px;
  bottom: 0;
  @media screen and (max-width: 1200px) {
    width: 450px;
    /* height: 250px; */
  }
  @media screen and (max-width: 990px) {
    right: 0;
    width: 400px;
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
const HeaderContainer = styled.div`
  flex: 0.4;
  text-align: left;
  @media screen and (max-width: 990px) {
    margin-left: 20px;
  }
  @media screen and (max-width: 768px) {
    margin-left: 0;
  }
`;

const Name = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.h3};
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fonts.header};
  margin-bottom: 20px;
  color: #fff;
  @media screen and (max-width: 990px) {
    font-size: ${({ theme }) => theme.fontSizes.title};
  }
`;
const Info1 = styled.div`
  font-family: ${({ theme }) => theme.fonts.heading};
  font-weight: bold;
  color: #666;
  @media screen and (max-width: 990px) {
    font-size: ${({ theme }) => theme.fontSizes.button};
  }
`;
const Info2 = styled.div`
  font-family: ${({ theme }) => theme.fonts.heading};
  color: ${({ theme }) => theme.colors.text.secondary};
  text-transform: uppercase;
  @media screen and (max-width: 990px) {
    font-size: ${({ theme }) => theme.fontSizes.button};
  }
`;
const Logo = styled.img`
  width: 50px;
  height: 50px;
  @media screen and (max-width: 768px) {
    margin-top: 10px;
  }
`;
