import React from "react";
import styled from "styled-components";
import About1 from "../../assets/images/about/about_img_1.jpeg";
import About2 from "../../assets/images/about/about_img_2.jpeg";
import About3 from "../../assets/images/about/about_img_3.jpeg";

const AboutMain = () => {
  return (
    <MainWrapper>
      <MainBox>
        <InfoBox>
          <Title>About Our Agency</Title>
          <Header>Your reliable collaborator for web solutions.</Header>
          <Descr>
            We are your dependable ally in the realm of web development. This
            key differentiator distinguishes us from our competitors and
            empowers us to offer a specialised consultancy service in web
            development. Our team leverages its extensive expertise to analyse
            and devise effective solutions. Since each channel is unique, there
            are common principles and strategies that underpin successful web
            development projects. Our team has honed these principles through
            practice, enabling us to consistently deliver outstanding results.
          </Descr>
        </InfoBox>
        <Box2>
          <img src={About1} />
        </Box2>
      </MainBox>
      <MainBox>
        <Box1>
          <img src={About2} />
        </Box1>
        <Box2>
          <img src={About3} />
        </Box2>
      </MainBox>
    </MainWrapper>
  );
};

export default AboutMain;

const MainWrapper = styled.div`
  width: 100%;
  min-height: 800px;
  padding: 20px 0;
  ${({ theme }) => theme.pageSizes.mediaInsideSize};
  ${({ theme }) => theme.flexing.columnCenter};
  @media screen and (max-width: 1000px) {
    width: 100%;
  }
`;
const MainBox = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  margin: 10px 0;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    margin: 0;
  }
`;
const Box1 = styled.div`
  flex: 0.6;
  margin-right: 20px;
  border-radius: 7px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
  }
  @media screen and (max-width: 1000px) {
    flex: 0.5;
    margin: 20px;
  }
`;
const Box2 = styled.div`
  flex: 0.4;
  border-radius: 7px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
  }
  @media screen and (max-width: 1000px) {
    flex: 0.5;
    margin: 20px;
  }
`;
const InfoBox = styled.div`
  flex: 0.6;
  margin-right: 20px;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1000px) {
    flex: 0.5;
    margin: 20px;
  }
`;
const Title = styled.div`
  flex: 0.1;
  width: 100%;
  font-size: ${({ theme }) => theme.fontSizes.title};
  color: ${({ theme }) => theme.colors.text.secondary};
`;

const Header = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.h3};
  flex: 0.3;
  color: ${({ theme }) => theme.colors.text.inverse};
  padding-top: 20px;
`;

const Descr = styled.div`
  font-family: ${({ theme }) => theme.fonts.heading};
  font-size: ${({ theme }) => theme.fontSizes.button};
  flex: 0.4;
  color: ${({ theme }) => theme.colors.text.inverse};
  margin-top: 20px;
`;
