import React from "react";
import styled from "styled-components";

const ModalBackdrop = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 99999;
  left: 0;
  bottom: 0;
  overflow: hidden;
  color: white;
  ${({ theme }) => theme.flexing.columnCenter};
`;

const ModalBox = styled.div`
  ${({ theme }) => theme.flexing.columnCenter};
  background: black;
  width: 90%;
  height: 300px;
  overflow: hidden;
  z-index: 999;

  @media screen and (max-width: 768px) {
    height: 500px;
  }
`;

const ModalButton = styled.button`
  font-size: ${({ theme }) => theme.fontSizes.button};
  width: 150px;
  text-align: center;
  background: white;
  color: black;
  margin: 20px;
  padding: 5px 20px;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.colors.ui.button};
  }
`;

const Modal = ({ message, onClose }) => {
  return (
    <ModalBackdrop>
      <ModalBox>
        <p>{message}</p>
        <ModalButton onClick={onClose}>Close</ModalButton>
      </ModalBox>
    </ModalBackdrop>
  );
};

export default Modal;
