import React from "react";
import styled from "styled-components";
import image from "../../assets/images/services/ecommerce.jpg";

const ServiceDetailedHeader = (props) => {
  return (
    <MainWrapper>
      <InfoWrapper>
        <Service>{props.service}</Service>
        <Descr>{props.caption}</Descr>
      </InfoWrapper>
      <ImageCoint>
        <Image src={props.image} alt="service image" />
      </ImageCoint>
    </MainWrapper>
  );
};

export default ServiceDetailedHeader;

const MainWrapper = styled.div`
  ${({ theme }) => theme.flexing.flexCenter};
  width: 100%;
  padding: 200px 0;
  background: ${({ theme }) => theme.colors.ui.black};

  @media screen and (max-width: 768px) {
    ${({ theme }) => theme.flexing.columnCenter};
  }
`;

const InfoWrapper = styled.div`
  width: 50%;
  ${({ theme }) => theme.flexing.columnCenter};
  color: ${({ theme }) => theme.colors.brand.inverse};

  @media screen and (max-width: 768px) {
    width: 100%;
    text-align: center;
    padding-bottom: 80px;
  }
`;

const Service = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.mobileHeader};

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const Descr = styled.div`
  width: 90%;
  padding-top: 20px;
  font-size: ${({ theme }) => theme.fontSizes.body};
  font-family: ${({ theme }) => theme.fonts.heading};

  @media screen and (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSizes.button};
  }
`;

const ImageCoint = styled.div`
  width: 50%;
  ${({ theme }) => theme.flexing.flexCenter};

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const Image = styled.img`
  width: 80%;
  height: auto;
  opacity: 0.5;

  @media screen and (max-width: 768px) {
    width: 95%;
  }
`;
