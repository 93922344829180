import React, { useState, useEffect } from "react";
import styled from "styled-components";

const AboutHeader = () => {
  const [load, setLoad] = useState(false);

  useEffect(() => {
    setLoad(true);
  }, []);

  return (
    <HeaderWrapper>
      <InsideWrapper>
        <TitleEffect load={load}>
          <OurWorksOne>about</OurWorksOne>
          <br />
          <OurWorksTwo>us</OurWorksTwo>
        </TitleEffect>
      </InsideWrapper>
    </HeaderWrapper>
  );
};

export default AboutHeader;

const HeaderWrapper = styled.div`
  width: 100%;
  height: 500px;
  ${({ theme }) => theme.flexing.flexCenter};
  @media screen and (max-width: 768px) {
    height: 400px;
  }
`;
const InsideWrapper = styled.div`
  width: 100%;
  height: 100%;

  ${({ theme }) => theme.pageSizes.mediaInsideSize};
`;
const TitleEffect = styled.div`
  width: 100%;
  opacity: ${({ load }) => (load ? 1 : 0)};
  transform: translateY(${({ load }) => (load ? "200px" : "250px")});
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;

  @media screen and (max-width: 768px) {
    width: 80%;
    margin-left: 20px;
  }
`;

const OurWorksOne = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.header};
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fonts.header};
  margin-right: 10px;
  color: ${({ theme }) => theme.colors.ui.navlink};
  text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
  @media screen and (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSizes.h2};
  }
  @media screen and (max-width: 400px) {
    font-size: ${({ theme }) => theme.fontSizes.h3};
  }
`;

const OurWorksTwo = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.header};
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fonts.header};
  margin: 0;
  color: ${({ theme }) => theme.colors.text.inverse};

  @media screen and (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSizes.h2};
  }
  @media screen and (max-width: 400px) {
    font-size: ${({ theme }) => theme.fontSizes.h3};
  }
`;

const Descr = styled.div`
  width: 100%;
  font-size: ${({ theme }) => theme.fontSizes.h5};
  font-family: ${({ theme }) => theme.fonts.heading};
  color: ${({ theme }) => theme.colors.text.inverse};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  padding: 20px 0 100px 0;

  @media screen and (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSizes.title};
  }
  @media screen and (max-width: 400px) {
    font-size: ${({ theme }) => theme.fontSizes.title};
  }
`;
