import React from "react";
import styled from "styled-components";
import PrivacySections from "./privacy.sections";
import { PrivacyDataEng } from "./privacy.data";

const PrivacyComponent = () => {
  return (
    <MainWrapper>
      <PrivacySections data={PrivacyDataEng} />
    </MainWrapper>
  );
};

export default PrivacyComponent;

const MainWrapper = styled.div`
  width: 100%;
  ${({ theme }) => theme.pageSizes.mediaInsideSize};
  padding: 50px 0;
  margin-left: auto;
  margin-right: auto;
  color: ${({ theme }) => theme.colors.text.inverse};
  @media screen and (max-width: 768px) {
    flex-direction: column;
    height: 70%;
  }
`;
