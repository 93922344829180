import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import HeaderImg from "../../assets/images/services/services_header.png";
import Button from "../button/Button";

const ServicesHeader = (props) => {
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);

  useEffect(() => {
    setLoad(true);
  }, []);

  return (
    <MainWrapper>
      <ContainerLeft load={load}>
        <HeaderWrapper>
          <Header>
            <h4>website</h4>
            <h4 className="white-text">creation</h4>
          </Header>
          <ParContainer>
            <p>
              Double-digit increases in traffic. Award-winning designs. 99%
              uptime. Expertise in 10+ dev technologies and platforms. Oh, and
              100% of clients have given us 5-star Google reviews.
            </p>
          </ParContainer>
          <ContactContainer>
            <Button buttonTheme={"dark"} text={"get in touch"} />
          </ContactContainer>
        </HeaderWrapper>
      </ContainerLeft>
      <ContainerRight load={load}>
        <img src={HeaderImg} />
      </ContainerRight>
    </MainWrapper>
  );
};

export default ServicesHeader;

const MainWrapper = styled.div`
  ${({ theme }) => theme.flexing.flexCenter};
  width: 90%;
  height: 600px;
  @media screen and (max-width: 1084px) {
    height: 900px;
    padding: 5%;
    ${({ theme }) => theme.flexing.columnCenter};
  }
  @media screen and (max-width: 768px) {
    height: 600px;
  }
`;

const ContainerLeft = styled.div`
  flex: 0.8;
  margin: 0 10px;
  height: 80%;
  opacity: ${({ load }) => (load ? 1 : 0)};
  transform: translateY(${({ load }) => (load ? "0" : "40px")});
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
`;

const ContainerRight = styled.div`
  flex: 1.2;
  margin: 0 10px;
  height: 80%;
  display: flex;
  align-items: center;
  opacity: ${({ load }) => (load ? 1 : 0)};
  transform: translateY(${({ load }) => (load ? "0" : "80px")});
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
  img {
    max-height: 100%;
    width: 100%;
  }
  @media screen and (max-width: 1084px) {
    align-items: flex-start;
    flex: 0.5;
    img {
      width: 100%;
    }
  }
  @media screen and (max-width: 1084px) {
    flex: 0.2;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Header = styled.div`
  flex: 0.5;
  font-size: 75px;

  text-transform: uppercase;
  font-family: "REM", sans-serif;

  font-weight: 700;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  h4 {
    margin: 0;
    color: black;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: white;
  }

  .white-text {
    -webkit-text-stroke: unset;
    color: white;
  }
  /* @media screen and (max-width: 1500px) {
    font-size: 50px;
  } */

  @media screen and (max-width: 768px) {
    font-size: 52px;
  }
  @media screen and (max-width: 400px) {
    font-size: 40px;
  }
`;

const ParContainer = styled.div`
  flex: 0.25;
  font-family: ${({ theme }) => theme.fonts.heading};
  @media screen and (max-width: 1500px) {
    font-size: 14px;
  }
`;

const ContactContainer = styled.div`
  flex: 0.25;
`;
