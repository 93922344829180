import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import LogoImg from "../../assets/images/logofadeswhite.png";
import { ImWhatsapp } from "react-icons/im";
import { FiFacebook, FiInstagram } from "react-icons/fi";
import FooterBg from "../../assets/images/footer/pattern-outline-4percent.png";

const FooterMain = () => {
  const navigate = useNavigate();

  return (
    <FooterWrapper>
      <InsideWrapper>
        <FooterLeft>
          <LeftContainer>
            <LogoWrapper>
              <Logo
                src={LogoImg}
                onClick={() => {
                  navigate("/home");
                }}
              />
            </LogoWrapper>

            <Title>
              Your Trusted <br /> Partner
            </Title>
          </LeftContainer>
          <RightContainer>
            <LinksWrapper>
              <Link
                onClick={() => {
                  navigate("/work");
                }}
              >
                Work
              </Link>
              <Link
                onClick={() => {
                  navigate("/expertise");
                }}
              >
                What we do
              </Link>
              <Link
                onClick={() => {
                  navigate("/about");
                }}
              >
                About Us
              </Link>
              <Link
                onClick={() => {
                  navigate("/contact");
                }}
              >
                Contact
              </Link>
            </LinksWrapper>
            <MobileSocialsWrapper>
              <SocialIcon href="https://wa.me/447511549707">
                <WhatsAppIcon color="" />
              </SocialIcon>
              {/* <SocialIcon>
                <FaceBookIcon />
              </SocialIcon> */}
              <SocialIcon
                href="https://www.instagram.com/gndtdigital"
                target="_blank"
              >
                <InstaIcon />
              </SocialIcon>
            </MobileSocialsWrapper>
          </RightContainer>
        </FooterLeft>
        <FooterRight>
          <LeftContainer></LeftContainer>

          <SocialsWrapper>
            <SocialIcon href="https://wa.me/447511549707">
              <WhatsAppIcon color="" />
            </SocialIcon>
            {/* <SocialIcon>
              <FaceBookIcon />
            </SocialIcon> */}
            <SocialIcon
              href="https://www.instagram.com/gndtdigital"
              target="_blank"
            >
              <InstaIcon />
            </SocialIcon>
          </SocialsWrapper>
        </FooterRight>
      </InsideWrapper>
    </FooterWrapper>
  );
};

export default FooterMain;

const FooterWrapper = styled.div`
  min-height: 200px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${FooterBg});
  background-size: cover;
  background-repeat: no-repeat;
`;
const InsideWrapper = styled.div`
  width: 100%;
  height: 70%;
  display: flex;
  padding: 0 3%;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    margin: 50px 0;
    padding: 0 5%;
  }
`;
const FooterLeft = styled.div`
  flex: 1;
  display: flex;
`;

const FooterRight = styled.div`
  flex: 1;
  display: flex;
`;

const LeftContainer = styled.div`
  flex: 1;
`;
const RightContainer = styled.div`
  flex: 1;
  @media screen and (max-width: 768px) {
    flex: 0.4;
  }
`;
const LogoWrapper = styled.div`
  width: 150px;
  margin-bottom: 10px;
  @media screen and (max-width: 300px) {
    width: 100px;
  }
`;
const Logo = styled.img`
  width: 75%;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    width: 70%;
  }
`;

const Title = styled.span`
  color: ${({ theme }) => theme.colors.text.secondary};
  font-weight: bold;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fonts.header};
  font-size: ${({ theme }) => theme.fontSizes.button};
`;

const LinksWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const Link = styled.a`
  color: #ccc;
  margin: 10px 0;
  font-size: ${({ theme }) => theme.fontSizes.button};
  font-family: ${({ theme }) => theme.fonts.heading};
  cursor: pointer;
  @media screen and (max-width: 300px) {
    margin: 5px 0;
  }
`;

const SocialsWrapper = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
const MobileSocialsWrapper = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  @media screen and (min-width: 769px) {
    display: none;
  }
`;

const SocialIcon = styled.a`
  width: 30px;
  height: 30px;
  margin: 0 20px 0 0;
  cursor: pointer;
`;

const WhatsAppIcon = styled(ImWhatsapp)`
  height: 100%;
  width: 100%;
  color: #ff3333;
`;
const FaceBookIcon = styled(FiFacebook)`
  height: 100%;
  width: 100%;
  color: #c83951;
`;
const InstaIcon = styled(FiInstagram)`
  height: 100%;
  width: 100%;
  color: #993366;
`;
