import React from "react";
import styled from "styled-components";

const Button = ({ buttonTheme, text,page }) => {
  return <ButtonWrap buttonTheme={buttonTheme} page={page}>{text}</ButtonWrap>;
};

export default Button;

const ButtonWrap = styled.div`
  width:${({page})=>page === "services" ? "150px" : "200px"};
  text-align: center;
  padding:${({page})=>page === "services" ? "5px" : "10px"};
  cursor: pointer;
  font-size:${({theme,page})=> page === "services" ? theme.fontSizes.button : theme.fontSizes.title.body};
  font-weight: bold;
  text-transform: uppercase;
  ${({ theme, buttonTheme }) =>
    buttonTheme === "dark"
      ? theme.colors.buttonTheme.darkTheme
      :
      buttonTheme === 'colored' ?
      theme.colors.buttonTheme.coloredTheme:
      theme.colors.buttonTheme.lightTheme};
  &:hover {
    transition: background 0.5s ease-in-out, color 0.5s ease-in-out;
  }
   

  @media screen and (max-width:768px){
    background:${({theme})=>theme.colors.ui.button};
  }
 
`;
