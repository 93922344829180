import React from "react";
import styled from "styled-components";
import { TermsDataEng } from "./terms.data";
import Section from "./section.component";
const TermsAndConditions = () => {
  return (
    <MainWrapper>
      {TermsDataEng.map((data, idx) => (
        <Section data={data} key={idx} />
      ))}
    </MainWrapper>
  );
};

export default TermsAndConditions;

const MainWrapper = styled.div`
  width: 100%;
  ${({ theme }) => theme.pageSizes.mediaInsideSize};

  margin-left: auto;
  margin-right: auto;
  color: ${({ theme }) => theme.colors.text.inverse};
  @media screen and (max-width: 768px) {
    flex-direction: column;
    height: 70%;
  }
`;
