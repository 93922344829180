import React from "react";
import styled from "styled-components";

const AboutComponent = () => {
  return (
    <MainDiv>
      <AboutWrapper>
        <Header>A DIVERSE TEAM OF DIGITAL EXPERTS</Header>
        <Par>
          GNDTDIGITAL is a rapidly expanding group of digital professionals who
          are closely connected, focusing on the most current developments and
          trends in digital marketing and development. Our team possesses
          substantial experience in crafting influential digital solutions that
          yield tangible outcomes. We collaborate with businesses worldwide to
          craft digital products and innovative marketing strategies that align
          with their corporate objectives.
        </Par>
        <Par>
          Are you in search of an impressive, top-performing website or digital
          campaign? One that not only secures a high position in search engine
          rankings but also adapts to the latest trends and, more significantly,
          captivates your audience, boosts sales, and fosters new business
          opportunities? We have the capability to turn this into reality.
        </Par>
      </AboutWrapper>
    </MainDiv>
  );
};

export default AboutComponent;

const MainDiv = styled.div`
  width: 100%;
  min-height: 400px;
  padding: 20px 0;
  color: ${({ theme }) => theme.colors.text.inverse};
  ${({ theme }) => theme.pageSizes.mediaInsideSize};
  ${({ theme }) => theme.flexing.columnCenter};
  @media screen and (max-width: 1000px) {
    width: 90%;
  }
`;
const AboutWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
`;
const Header = styled.div`
  flex: 0.4;
  height: 100%;
  padding: 0 10%;
  font-family: ${({ theme }) => theme.fonts.header};
  font-size: ${({ theme }) => theme.fontSizes.h2};
  @media screen and (max-width: 1000px) {
    font-size: ${({ theme }) => theme.fontSizes.h3};
    padding: 0;
  }
  @media screen and (max-width: 500px) {
    font-size: ${({ theme }) => theme.fontSizes.h4};
  }
`;
const Par = styled.div`
  flex: 0.3;
  font-family: ${({ theme }) => theme.fonts.heading};
  padding: 0 15%;
  margin: 10px 0;
  @media screen and (max-width: 1000px) {
    font-size: ${({ theme }) => theme.fontSizes.button};
    padding: 0 5%;
  }
  @media screen and (max-width: 500px) {
    font-size: ${({ theme }) => theme.fontSizes.caption};
  }
`;
