import React, { useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";

import Button from "../button/Button";

const ServicesListCard = ({ data }) => {
  const navigate = useNavigate();
  const { service, serviceLogo, text, img, rightSideScroll } = data;

  const handleNavigate = (location) => {
    navigate(location);
  };
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  const animation = {
    initial: {
      opacity: 0,
      transform: rightSideScroll ? ` translatex(100px)` : "translateX(-100px)",
    },
    animate: { opacity: 1, transform: "translatex(0)" },
  };

  return (
    <motion.div
      ref={ref}
      initial={animation.initial}
      animate={inView ? animation.animate : animation.initial}
    >
      <CardWrapper image={img}>
        <BackgroundImageWrapper>
          <BackgroundImage src={img} />
          <Shading />
        </BackgroundImageWrapper>
        <InfoWrapper>
          {/* <IconWrapper>{serviceLogo}</IconWrapper> */}

          <Title>{service}</Title>
          <Details>{text}</Details>
          <ButtonWrapper
            onClick={() => {
              handleNavigate(`/expertise/${data.id}`);
            }}
          >
            <Button
              text="find out more"
              buttonTheme="colored"
              page={"services"}
            />
          </ButtonWrapper>
        </InfoWrapper>
      </CardWrapper>
    </motion.div>
  );
};

export default ServicesListCard;

const CardWrapper = styled.div`
  height: 250px;
  width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 4px;
  position: relative;

  ${
    "" /* @media screen and (max-width: 768px) {
    width: 400px;
  } */
  }

  @media screen and (max-width: 500px) {
    width: 98%;
  }
`;

const BackgroundImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;

  ${
    "" /* @media screen and (min-width:0px){
  display:none;
} */
  }
`;

const BackgroundImage = styled.img`
  width: 100%;
  height: 100%;
`;

const Shading = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  ${"" /* filter: blur(4px); */}
`;

const InfoWrapper = styled.div`
  height: 100%;
  width: 85%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
const IconWrapper = styled.div`
  ${"" /* flex:0.2; */}
  padding-top:20px;
  ${({ theme }) => theme.flexing.flexCenter};
`;

const Title = styled.div`
  ${"" /* flex:0.2; */}
  padding:10px 0px;
  ${"" /* ${({ theme }) => theme.flexing.flexCenter}; */}
`;
const Details = styled.div`
  flex: 0.4;
  /* ${({ theme }) => theme.flexing.flexCenter}; */
  font-family: ${({ theme }) => theme.fonts.heading};
  font-size: ${({ theme }) => theme.fontSizes.button};
`;
const ButtonWrapper = styled.div`
  flex: 0.1;
  ${({ theme }) => theme.flexing.flexCenter};

  ${
    "" /* @media screen and (max-width:767px){
    display:none;
  } */
  }
`;
