import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const CardWork = (props) => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(`/project/${props.title}`);
  };
  return (
    <CardContainer bg={props.background}>
      <MainWrap onClick={handleNavigate}>
        <Info>
          <Title>{props.title}</Title>
          <ProjectType>{props.projtype}</ProjectType>
        </Info>
      </MainWrap>
    </CardContainer>
  );
};

export default CardWork;

const CardContainer = styled.a`
  position: relative;
  width: 50%;
  height: 400px;
  cursor: pointer;
  background: ${({ bg }) => `url(${bg})`};
  background-repeat: no-repeat;
  background-size: cover;

  &:hover::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
  }

  @media screen and (max-width: 500px) {
    ${({ theme }) => theme.flexing.columnCenter};
    width: 100%;
    height: 280px;
  }

  @media screen and (min-width: 501px) and (max-width: 820px) {
    ${({ theme }) => theme.flexing.columnCenter};
    width: 100%;
    height: 450px;
  }

  @media screen and (max-width: 500px) {
    width: 100%;
  }
`;

const MainWrap = styled.div`
  position: relative;
  ${({ theme }) => theme.flexing.flexCenter};
  width: 100%;
  height: 100%;
  color: ${({ theme }) => theme.colors.text.inverse};
  z-index: 1;

  @media screen and (max-width: 767px) {
    ${({ theme }) => theme.flexing.columnCenter};
    height: 450px;
    align-self: flex-start;
  }
`;

const Info = styled.div`
  ${({ theme }) => theme.flexing.columnCenter}
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  align-items: flex-start;
  padding-left: 50px;
  font-family: ${({ theme }) => theme.fonts.heading};
  ${"" /* -webkit-appearance: none; */}
  box-shadow: 1px -120px 61px -40px ${({ theme }) => theme.colors.ui.button}
    inset;
  -webkit-box-shadow: 1px -120px 61px -40px
    ${({ theme }) => theme.colors.ui.button} inset;
  -moz-box-shadow: 1px -120px 61px -40px
    ${({ theme }) => theme.colors.ui.button} inset;
  opacity: 0;
  transition: opacity 0.3s;

  &:hover {
    opacity: 1;
  }

  @media screen and (max-width: 767px) {
    align-items: center;
    padding-left: 0;
    opacity: 1;
  }

  @media screen and (max-width: 768px) and (-webkit-min-device-pixel-ratio: 2) {
    height: auto;
    position: absolute;
    bottom: 0;
    box-shadow: none;
    background: linear-gradient(
      to right,
      ${({ theme }) => theme.colors.ui.button},
      ${({ theme }) => theme.colors.ui.comopac}
    );
    opacity: 1;
  }
`;

const Title = styled.div`
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes.h5};

  @media screen and (max-width: 860px) {
    font-size: ${({ theme }) => theme.fontSizes.title};
  }

  @media screen and (max-width: 768px) and (-webkit-min-device-pixel-ratio: 2) {
    padding-top: 10px;
  }
`;

const ProjectType = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.button};
  font-family: ${({ theme }) => theme.fonts.heading};
  margin: 0 0 15px 0;

  @media screen and (max-width: 860px) {
    font-size: ${({ theme }) => theme.fontSizes.button};
    margin: 0 0 10px 0;
  }
`;
