import React from "react";
import styled from "styled-components";
import CardWorkWrapper from "../components/workComponents/CardWorkWrapper";

const Work = () => {
  return (
    <WorkWrapper>
      <WorkInsideWrapper>
        <CardWorkWrapper />
      </WorkInsideWrapper>
    </WorkWrapper>
  );
};

export default Work;

const WorkWrapper = styled.div`
  min-height: 600px;
  ${({ theme }) => theme.pageSizes.media};
  overflow: hidden;
`;

const WorkInsideWrapper = styled.div`
  width: 100%;
  min-height: 600px;
  background: ${({ theme }) => theme.colors.proposal.work};
  color: #fff;
  ${({ theme }) => theme.flexing.columnCenter};
`;
