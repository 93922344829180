import Img1 from "../../assets/images/about/chris.jpg";
import Daniele from "../../assets/images/team/Daniele.png";
import Gyula2 from "../../assets/images/team/Gyula2.png";
import Img2 from "../../assets/images/about/hemsworth.png";
import Img3 from "../../assets/images/about/monica.jpg";
import Img4 from "../../assets/images/about/ryan.jpg";
import Img5 from "../../assets/images/about/henry.jpg";

export const teamData = [
  {
    id: 1,
    img: Daniele,
    name: "Daniele Tursi",
    position: "Founder/React Developer",
    details:
      "Daniele is a skilled web developer with a strong background in front-end and back-end development. He has a passion for creating responsive and user-friendly websites that provide a seamless user experience. With expertise in a variety of web technologies and programming languages, John is dedicated to delivering high-quality web solutions tailored to meet the unique needs of clients and end-users.",
  },
  {
    id: 2,
    img: Gyula2,
    name: "Gyula Nagy",
    position: "Founder/React Developer",
    details:
      "Gyula is a skilled web developer with a strong background in front-end and back-end development. He has a passion for creating responsive and user-friendly websites that provide a seamless user experience. With expertise in a variety of web technologies and programming languages, John is dedicated to delivering high-quality web solutions tailored to meet the unique needs of clients and end-users.",
  },
  {
    id: 3,
    img: Img3,
    name: "Carmela Pico",
    position: "Digital Designer",
    details:
      "Carmela is a skilled Designer . She has a passion for creating responsive and user-friendly websites that provide a seamless user experience. With expertise in a variety of web technologies and programming languages, John is dedicated to delivering high-quality web solutions tailored to meet the unique needs of clients and end-users.",
  },
  // {
  //   id: 4,
  //   img: Img4,
  //   name: "Amedeo Seren Rosso",
  //   position: "External Advisor",
  // },
  // {
  //   id: 5,
  //   img: Img5,
  //   name: "Henry",
  //   position: "Reporter",
  // },
];
