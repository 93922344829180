import React, { createContext, useState } from "react";

const ColorContext = createContext();

const colorMapping = {
  "/home": {
    desktop: "#000",
    mobile: "rgba(0,0,0,0.9)",
  },
  "/work": {
    desktop: `#310335`,
    mobile: "rgba(49,3,53,0.9)",
  },
  "/expertise": {
    // desktop: "#002722",
    // mobile: "rgba(0,39,34,0.9)",
    desktop: "rgb(64, 23, 100)",
    mobile: "rgba(0,0,0,0.9)",
  },
  "/about": {
    desktop: "#200319",
    mobile: "rgba(32,3,25,0.9)",
  },
  "/contact": {
    desktop: "#052b3e",
    mobile: "rgba(5,43,62,0.9)",
  },
  "/terms": {
    desktop: "#052b3e",
    mobile: "rgba(5,43,62,0.9)",
  },
  "/privacy": {
    desktop: "#052b3e",
    mobile: "rgba(5,43,62,0.9)",
  },
  default: {
    desktop: "#000",
    mobile: "rgba(0,0,0,0.9)",
  },
};

export const ColorProvider = ({ children }) => {
  const [background, setBackground] = useState(colorMapping.default.desktop);
  const [mobileBackground, setMobileBackground] = useState(
    colorMapping.default.mobile
  );

  const changeBackgroundColor = (newPath) => {
    const { desktop, mobile } = colorMapping[newPath] || colorMapping.default;
    setBackground(desktop);
    setMobileBackground(mobile);
    return desktop;
  };

  return (
    <ColorContext.Provider
      value={{
        background,
        mobileBackground,
        changeBackgroundColor,
      }}
    >
      {children}
    </ColorContext.Provider>
  );
};

export default ColorContext;
