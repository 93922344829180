import React from "react";
import styled from "styled-components";

const Projectdesign2 = (props) => {
  return (
    <MainWrap>
      <HeaderImage src={props.img2} alt={"website mockup"} />
      <BodyWrap>
        <BodyImage src={props.img3} alt={"website image"} />
        <InfoWrap>
          <Title>{props.title}</Title>
          <Info>{props.info}</Info>
        </InfoWrap>
      </BodyWrap>
      <HeaderImage src={props.img4} alt={"website mockup"} />
      <BottomWrap>
        <InfoWrapButton>
          {/* <Title>{props.title}</Title> */}
          <Info>{props.info2}</Info>
        </InfoWrapButton>
        <DescrImage src={props.img5} alt={"website mockup"} />
      </BottomWrap>
    </MainWrap>
  );
};

export default Projectdesign2;

const MainWrap = styled.div`
  ${({ theme }) => theme.flexing.columnCenter};
  background: ${({ theme }) => theme.colors.ui.black};
  color: ${({ theme }) => theme.colors.brand.inverse};

  @media screen and (max-width: 768px) {
    margin-top: 70px;
  }
`;

const HeaderImage = styled.img`
  width: 100%;
  height: auto;
`;

const BodyWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: ${({ reverse }) => (reverse ? "row-reverse" : "row")};
  width: 100%;
  margin: 4px 0;

  @media screen and (max-width: 768px) {
    ${({ theme }) => theme.flexing.columnCenter};
  }
`;

const BodyImage = styled.img`
  width: 25%;
  height: auto;

  ${
    "" /* clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%); */
  }

  @media screen and (max-width: 768px) {
    display: none;
    width: 35%;
  }
`;

const InfoWrap = styled.div`
  ${({ theme }) => theme.flexing.columnCenter};
  width: 40%;
  margin: 0 10%;

  @media screen and (max-width: 768px) {
    text-align: center;
    width: 95%;
  }
`;

const Title = styled.h1`
  font-size: ${({ theme }) => theme.fontSizes.h5};
  text-transform: uppercase;
`;

const Info = styled.h2`
  font-size: ${({ theme }) => theme.fontSizes.body};
  font-family: ${({ theme }) => theme.fonts.heading};
  font-weight: normal;

  @media screen and (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSizes.caption};
  }
`;

const DescrImage = styled.img`
  width: 30%;
  height: auto;

  @media screen and (max-width: 768px) {
    width: 100%;
    padding-top: 20px;
  }
`;

const BottomWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 4px 0;

  @media screen and (max-width: 800px) {
    ${({ theme }) => theme.flexing.columnCenter};
  }
`;

const InfoWrapButton = styled.div`
  ${({ theme }) => theme.flexing.columnCenter};
  width: 40%;
  margin: 0 10%;

  @media screen and (max-width: 800px) {
    width: 90%;
    text-align: center;
  }
`;
