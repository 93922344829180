import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import ServiceDetailedHeader from "./ServiceDetailedHeader";
import ServiceDetailedBody from "./ServiceDetailedBody";
import { specializationData } from "../homeComponents/Specialization.data";

const ServiceDetailed = () => {
  const { serviceId } = useParams();
  const [serviceData, setServiceData] = useState({});

  useEffect(() => {
    const service = specializationData.find((srv) => srv.id === +serviceId);

    if (service) {
      setServiceData(service);
    }
  }, []);
  return (
    <MainWrap>
      <ServiceDetailedHeader service={serviceData.service} caption={serviceData.caption} image={serviceData.img}/>
      <ServiceDetailedBody bodyimg={serviceData.img2} text2={serviceData.text2} text3={serviceData.text3} action={serviceData.action}/>
    </MainWrap>
  );
};

export default ServiceDetailed;

const MainWrap = styled.div`
  width: 100%;
  min-height: 900px;

  h1 {
    padding: 400px;
    color: white;
  }
`;
