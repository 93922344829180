export const sizes = ["8px", "16px", "32px", "64px", "128px"];

export const pageSizes = {
  padding: "padding:0 40px",
  media: "@media screen and (min-width: 1300px) {padding:0 40px}",
  mediaInsideSize:
    "  @media screen and (min-width: 576px) {max-width: 540px;}@media screen and (min-width: 768px) {max-width: 720px;}@media screen and (min-width: 992px) {max-width: 960px;}@media screen and (min-width: 1200px) {max-width: 1140px;}",
};

export const connectSizes = {
  pointSize: "width: 15px;height: 15px;margin-right: 10px;",
};
