import React, { useState, useEffect } from "react";
import styled from "styled-components";
import CardWork from "./CardWork";
import { works } from "./WorkData";

const CardWorkWrapper = () => {
  const [load, setLoad] = useState(false);

  useEffect(() => {
    setLoad(true);
  }, []);

  return (
    <MainWrap>
      <InsideWrapper>
        <TitleEffect load={load}>
          <OurWorksOne>OUR RECENT</OurWorksOne>
          <OurWorksTwo>WORK</OurWorksTwo>
          <Descr>Few of our recent projects.</Descr>
        </TitleEffect>
        <WorkWrap>
          {works.map((work, idx) => (
            <CardWork
              key={idx}
              background={work.background}
              title={work.title}
              projtype={work.projecttype}
              projname={work.projectname}
            />
          ))}
        </WorkWrap>
      </InsideWrapper>
    </MainWrap>
  );
};

export default CardWorkWrapper;

const MainWrap = styled.div`
  ${({ theme }) => theme.flexing.columnCenter};
  max-width: 100%;

  @media screen and (max-width: 500px) {
    width: 100%;
  }
`;

const InsideWrapper = styled.div`
  width: 100%;
  height: 100%;

  ${({ theme }) => theme.pageSizes.mediaInsideSize};

  @media screen and (max-width: 768px) {
    ${({ theme }) => theme.flexing.columnCenter};
  }
`;

const TitleEffect = styled.div`
  width: 100%;
  opacity: ${({ load }) => (load ? 1 : 0)};
  transform: translateY(${({ load }) => (load ? "0" : "40px")});
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;

  @media screen and (max-width: 767px) {
    padding-left: 30px;
  }
`;

const OurWorksOne = styled.div`
  width: 100%;
  font-size: 75px;
  padding-top: 200px;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fonts.header};
  margin: 0;
  color: ${({ theme }) => theme.colors.ui.navlink};
  text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
  @media screen and (max-width: 768px) {
    font-size: 52px;
  }
  @media screen and (max-width: 400px) {
    font-size: 40px;
  }
`;

const OurWorksTwo = styled.div`
  width: 100%;
  font-size: 75px;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fonts.header};
  margin: 0;
  color: white;

  @media screen and (max-width: 768px) {
    font-size: 52px;
  }
  @media screen and (max-width: 400px) {
    font-size: 40px;
  }
`;

const Descr = styled.div`
  width: 100%;
  font-size: ${({ theme }) => theme.fontSizes.h5};
  font-family: ${({ theme }) => theme.fonts.heading};
  color: ${({ theme }) => theme.colors.text.inverse};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  padding: 20px 0 0px 0;

  @media screen and (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSizes.title};
  }
  @media screen and (max-width: 400px) {
    font-size: ${({ theme }) => theme.fontSizes.title};
  }
`;

const WorkWrap = styled.div`
  width: 100%;
  ${({ theme }) => theme.flexing.flexCenter};
  flex-wrap: wrap;
  margin: 50px 0;
`;
