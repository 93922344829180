import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { useParams } from "react-router-dom";
import { teamData } from "../components/AboutComponents/teamData";
import { FaLinkedinIn, FaFacebookF, FaInstagram } from "react-icons/fa";

const TeamDetailsPage = () => {
  const { teamId } = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    // Find the object in teamData with the matching id
    const teamMember = teamData.find((member) => member.id === Number(teamId));

    if (teamMember) {
      setData(teamMember);
    }
  }, [teamId]);

  return (
    <TeamWrapper>
      <TeamInsideWrapper>
        {data && (
          <>
            <MemberWrapper>
              <ImageWrapper>
                <img src={data.img} />
              </ImageWrapper>

              <DataWrapper>
                <NameWrapper>{data.name}</NameWrapper>
                <PositionWrapper>{data.position}</PositionWrapper>
                <Desc>{data.details}</Desc>
                <SocialsWrapper>
                  <FaceBookIcon />

                  <LinkedinIcon />

                  <InstaIcon />
                </SocialsWrapper>
              </DataWrapper>
            </MemberWrapper>
            {/* Add more data fields as needed */}
          </>
        )}
      </TeamInsideWrapper>
    </TeamWrapper>
  );
};

export default TeamDetailsPage;

const TeamWrapper = styled.div`
  margin-top: 70px;
  min-height: 600px;
  ${({ theme }) => theme.pageSizes.media};
  display: flex;
  justify-content: center;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const TeamInsideWrapper = styled.div`
  width: 100%;
  min-height: 600px;
  background: #000;
  color: #fff;
  ${({ theme }) => theme.pageSizes.mediaInsideSize};
  ${({ theme }) => theme.flexing.columnCenter};
`;

const MemberWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
  }
`;
const ImageWrapper = styled.div`
  flex: 1;
  img {
    width: 400px;
    height: 500px;
    border-radius: 7px;
    margin-left: 10%;
  }
  @media screen and (max-width: 768px) {
    img {
      width: 250px;
      height: 300px;
      margin-left: 0;
    }
  }
`;
const DataWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 80%;
  margin-left: 50px;
  @media screen and (max-width: 768px) {
    margin-left: 0;
    padding: 0 5%;
  }
`;
const NameWrapper = styled.div`
  flex: 0.1;
  font-size: ${({ theme }) => theme.fontSizes.h5};
  @media screen and (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSizes.h6};
  }
`;
const PositionWrapper = styled.div`
  flex: 0.1;
  color: ${({ theme }) => theme.colors.text.secondary};
`;
const Desc = styled.div`
  flex: 0.7;
  font-family: ${({ theme }) => theme.fonts.heading};
  @media screen and (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSizes.caption};
    margin-top: 10px;
  }
`;
const SocialsWrapper = styled.div`
  flex: 0.1;
  width: 30%;
  height: 30px;
  left: 10px;
  top: 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 768px) {
    margin-top: 10px;
  }
`;

const IconsStyle = css`
  width: 20px;
  height: 20px;
  color: white;
  cursor: pointer;

  &:hover {
    color: #ba1193;
  }
`;

const FaceBookIcon = styled(FaFacebookF)`
  ${IconsStyle};
`;

const InstaIcon = styled(FaInstagram)`
  ${IconsStyle};
`;

const LinkedinIcon = styled(FaLinkedinIn)`
  ${IconsStyle};
`;
