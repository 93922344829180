import { FaShoppingBasket } from "react-icons/fa";
import { TiSupport } from "react-icons/ti";
import { MdApps, MdAppShortcut } from "react-icons/md";
import { CgWebsite } from "react-icons/cg";
import { GiChameleonGlyph } from "react-icons/gi";
import Branding from "../../assets/images/services/branding.jpg";
import Brandingbody from "../../assets/images/services/brandingbody.jpg";
import Websitedesign from "../../assets/images/services/websitedesign.jpg";
import Webdesignbody from "../../assets/images/services/webdesignbody.jpg";
import Webapps from "../../assets/images/services/webapps.jpg";
import Webappsbody from "../../assets/images/services/webappsbody.jpg";
import Mobileapps from "../../assets/images/services/mobileapps.jpg";
import Mobileappsbody from "../../assets/images/services/mobileappsbody.jpg";
import Ecommerce from "../../assets/images/services/ecommerce.jpg";
import Ecommercebody from "../../assets/images/services/ecommercebody.jpg";
import Testing from "../../assets/images/services/testing.jpg";
import Testingbody from "../../assets/images/services/testingbody.jpg";
import Office from "../../assets/images/services/office.jpg";

const style1 = {
  width: "40px",
  height: "40px",
  color: "rgba(197, 47, 83, 1)",
};

export const specializationData = [
  {
    id: 1,
    service: "Branding",
    logo: <GiChameleonGlyph />,
    serviceLogo: <GiChameleonGlyph style={style1} />,
    text: "Elevate your brand with our logo design and branding expertise.",
    img: Branding,
    img2: Brandingbody,
    rightSideScroll: true,
    caption:
      "Branding is the strategic process of creating and managing a distinct identity for your business. It involves developing visual and messaging elements that convey your unique story and values, helping you establish a memorable presence in the minds of your audience.",
    text2:
      "Comprehensive branding plays a multifaceted role in your business strategy, serving as the cornerstone of your identity to distinguish you from competitors, establishing trust and loyalty that encourages repeat business, maintaining a consistent image and message across various marketing channels, setting you apart in a competitive market, and even forging emotional connections with customers, all collectively enhancing brand recognition and customer engagement.",
    text3:
      "Our branding service combines user-friendly tools, extensive customisation options, security measures, mobile optimisation, and advanced SEO and marketing features to provide businesses of all sizes with a comprehensive solution for crafting and managing a brand identity that aligns perfectly with their essence and goals, while also ensuring the security of brand assets, optimizing for mobile devices, and enhancing online visibility to attract and engage a broader audience.",
    action:
      "Ready to elevate your brand's identity and strengthen its impact? Contact us today for a free consultation and discover how our branding solutions can help you create a lasting impression and connect with your target audience.",
  },
  {
    id: 2,
    service: "Web Design",
    logo: <CgWebsite />,
    serviceLogo: <CgWebsite style={style1} />,
    text: "Experience top-tier brand elevation through our expert website design services.",
    img: Websitedesign,
    img2: Webdesignbody,
    rightSideScroll: false,
    caption:
      "Web design is the deliberate and strategic process of crafting and managing the visual and functional elements of your website, ensuring it reflects your unique story, values, and business objectives, with the aim of creating a memorable and engaging online presence for your audience.",
    text2:
      "Effective web design encompasses a multifaceted approach, including user-friendly navigation that enhances the overall user experience by making it effortless for visitors to navigate, find information, and interact with your content or products, maintaining brand consistency across all online touchpoints, which is instrumental in reinforcing brand recognition and trust among your audience, providing a competitive edge in the crowded digital landscape by combining aesthetically appealing design with functional elements that captivate and engage your target audience, implementing conversion optimisation strategies that encourage desired visitor actions, such as making purchases, subscribing to newsletters, or contacting your business, and embracing mobile responsiveness to ensure a seamless and enjoyable experience for customers accessing your website on smartphones and tablets, ultimately contributing to the establishment of a strong and successful online presence.",
    text3:
      "Our web design service provides user-friendly tools for businesses of all sizes, extensive customisation options, robust security features, full mobile optimisation, and effective SEO and digital marketing tools to enhance online visibility and brand recognition.",
    action:
      "Ready to enhance your online presence and create a website that leaves a lasting impression? Contact us today for a free consultation and discover how our web design solutions can help you connect with your target audience.",
  },
  {
    id: 3,
    service: "Web Apps",
    logo: <MdApps />,
    serviceLogo: <MdApps style={style1} />,
    text: "Elevate your brand with our expert web app development services.",
    img: Webapps,
    img2: Webappsbody,
    rightSideScroll: true,
    caption:
      "Web apps are crucial for modern businesses because they offer several advantages. They are highly accessible, require no installation, and can be used on various devices, making them a versatile solution.",
    text2:
      "Our web app service offers a multifaceted approach to improving your business operations. With the convenience of accessibility from anywhere, real-time collaboration capabilities, scalable solutions that adapt to your evolving needs, and the added advantage of reduced maintenance costs, you can streamline processes, boost efficiency, and enhance your overall user experience.",
    text3:
      "What sets our web app service apart? Our commitment to innovation and user-friendly design ensures that you get a tailored solution that perfectly matches your needs. Plus, our responsive customer support team is always here to assist you.",
    action:
      "Ready to transform your business? Get started today and experience the power of our web app solutions.",
  },
  {
    id: 4,
    service: "Mobile Apps",
    logo: <MdAppShortcut />,
    serviceLogo: <MdAppShortcut style={style1} />,
    text: "Achieve brand excellence with our specialised mobile app development.",
    img: Mobileapps,
    img2: Mobileappsbody,
    rightSideScroll: false,
    caption:
      "A mobile app is like a digital Swiss army knife for your clients' services, reaching their customers via smartphones or tablets. It's a smart software tool that brings all the benefits of their service right to their customers' fingertips.",
    text2:
      "Experience the transformative power of our app development expertise, where we seamlessly craft solutions that empower you to effortlessly connect with your audience from anywhere, streamline your operations for newfound efficiency, and engage on a deeper level with tailored experiences and real-time updates, all custom-crafted to meet your unique needs and accessible right at your fingertips.",
    text3:
      "What sets our mobile app development service apart? Our unwavering commitment to innovation and user-centric design guarantees a customised solution perfectly aligned with your unique needs. Moreover, our responsive customer support team is readily available to assist you throughout your app's journey.",
    action:
      "Ready to transform your vision into a remarkable mobile app? Get started now, and let's make your app idea a reality!",
  },
  {
    id: 5,
    service: "eCommerce",
    logo: <FaShoppingBasket />,
    serviceLogo: <FaShoppingBasket style={style1} />,
    text: "Elevating retail through digital commerce, delivering to everyone's home with one click.",
    img: Ecommerce,
    img2: Ecommercebody,
    rightSideScroll: true,
    caption:
      "E-commerce, short for electronic commerce, is the process of buying and selling products or services over the internet. In simple terms, it's online shopping. It involves businesses creating digital storefronts or websites where customers can browse, select, and purchase items or services using electronic payment methods.",
    text2:
      "E-commerce offers a range of advantages, including global reach by breaking down geographical barriers, enabling businesses to access a worldwide audience, 24/7 availability for customers to shop at their convenience, cost-effectiveness through the elimination of physical storefront expenses and potential labor cost savings through automation, data-driven insights from platforms that provide valuable customer behavior and sales trend data to inform decisions and refine marketing strategies, and scalability, allowing businesses to quickly adapt to changing demand or expansion into new markets.",
    text3:
      "Our e-commerce service provides user-friendly platforms with extensive customisation options, robust security features, full mobile optimisation, and SEO and marketing tools, making it accessible to all users, adaptable to unique business needs, and prioritizing security, mobile shopping experience, and online visibility.",
    action:
      "Ready to take your business online and harness the power of e-commerce? Contact us today for a free consultation and discover how our e-commerce solutions can drive growth and success for your business.",
  },
  {
    id: 6,
    service: "Maintenance & Support",
    logo: <TiSupport />,
    serviceLogo: <TiSupport style={style1} />,
    text: "Ensure brand success with dedicated support and maintenance.",
    img: Testing,
    img2: Testingbody,
    rightSideScroll: false,
    caption:
      "Cyber support, often referred to as digital maintenance and support, is the process of ensuring the ongoing functionality of your digital assets. In simple terms, it's like having a virtual caretaker for your online presence. It involves dedicated professionals who proactively monitor, troubleshoot, and optimize your digital systems to prevent disruptions, ensuring they operate smoothly and securely, allowing you to focus on your core business activities.",
    text2:
      "Effective maintenance and support are essential to keep your digital presence running smoothly. These services offer several benefits, including uninterrupted operations, ensuring your business is always accessible to customers, cost-effectiveness by preventing costly downtime and system failures, data-driven insights into system performance and user behavior, and scalability to adapt to evolving business needs.",
    text3:
      "Our maintenance and support service is designed to provide you with a seamless experience. We offer user-friendly platforms with extensive customisation options, and 7 days a week availability to address any issues promptly. With a strong focus on reliability and ensuring your systems are always up and running, we prioritize the stability and optimal performance of your digital assets.",
    action:
      "Ready to secure the continuous performance of your digital assets? Contact us today for a free consultation and discover how our maintenance and support solutions can safeguard your online presence, prevent disruptions, and drive sustained success for your business.",
  },
];
