import React, { useState, useEffect } from "react";
import { styled } from "styled-components";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router";
import CookiePopup from "./CookiesCustomize";

const CookieConsent = () => {
  const [cookies, setCookie] = useCookies(["cookieConsent"]);
  const [cookiesPreferences, setCookiesPreferences] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {}, [cookiesPreferences]);
  const giveCookieConsent = () => {
    setCookie("cookieConsent", true, { path: "/" });
  };

  const manageCookieConsent = () => {
    setCookiesPreferences(true);
  };

  return cookiesPreferences === false ? (
    <MainWrap>
      <CookiesWrap>
        <Text>
          We use cookies to enhance your user experience. By using our website,
          you agree to our use of cookies.
        </Text>
        <Link onClick={() => navigate("/privacy")}>Learn more.</Link>
        <ButtonWrap>
          <Button onClick={giveCookieConsent} buttonTheme="dark">
            Accept All
          </Button>
          <Button onClick={manageCookieConsent} buttonTheme="dark">
            Customize
          </Button>
        </ButtonWrap>
      </CookiesWrap>
    </MainWrap>
  ) : (
    <ModalWrap>
      <Modal>
        <CookiePopup />
      </Modal>
    </ModalWrap>
  );
};

export default CookieConsent;

const MainWrap = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  z-index: 1;
  left: 0;
  bottom: 0;
  overflow: hidden;
  color: white;
`;

const CookiesWrap = styled.div`
  ${({ theme }) => theme.flexing.columnCenter};
  background: black;
  width: 100%;
  height: 200px;
  position: absolute;
  left: 0;
  bottom: 0;
  overflow: hidden;
`;

const Text = styled.div`
  padding: 20px 0;
  font-size: ${({ theme }) => theme.fontSizes.button};
`;

const Link = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.button};
  text-decoration: underline;
  cursor: pointer;
`;

const ButtonWrap = styled.div`
  ${({ theme }) => theme.flexing.flexCenter};
`;

const Button = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.button};
  width: 200px;
  text-align: center;
  background: white;
  color: black;
  margin: 20px;
  padding: 5px 20px;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.colors.ui.button};
  }
`;

const ModalWrap = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
`;

const Modal = styled.div`
  ${({ theme }) => theme.flexing.flexCenter};
  justify-content: center;
`;
