import React from "react";
import styled from "styled-components";
import CardProject from "./CardProject";
import { projects } from "./ProjectsData";
import { works } from "../../components/workComponents/WorkData";
import CardWork from "../workComponents/CardWork";

const CardProjectWrapper = () => {
  return (
    <MainWrap>
      <InsideWrapper>
        {/* <OurProjects>What we do</OurProjects> */}
        <RecentText> Our latest Projects</RecentText>
        <ProjectsWrap>
          {works.map((proj, idx) => (
            <CardWork
              key={idx}
              background={proj.background}
              title={proj.title}
              projtype={proj.projecttype}
              projname={proj.projectname}
            />
          ))}
        </ProjectsWrap>
      </InsideWrapper>
    </MainWrap>
  );
};

export default CardProjectWrapper;

const MainWrap = styled.div`
  ${({ theme }) => theme.flexing.flexCenter};
  background: ${({ theme }) => theme.colors.ui.secondary};
  flex-wrap: wrap;
  width: 100%;
  padding: 100px 0;
  @media screen and (max-width: 768px) {
    padding: 50px 0;
  }
  color: ${({ theme }) => theme.colors.text.inverse};
`;
const InsideWrapper = styled.div`
  ${({ theme }) => theme.pageSizes.mediaInsideSize};
  ${({ theme }) => theme.flexing.columnCenter};
  width: 100%;
  min-height: 900px;
  margin-left: auto;
  margin-right: auto;
`;

const OurProjects = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.body};
  width: 100%;
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  text-align: left;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  font-family: ${({ theme }) => theme.fonts.heading};

  @media screen and (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSizes.body};
    width: 95%;
  }
`;

const RecentText = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.h5};
  width: 95%;
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  text-align: left;
  padding: 0 0 40px 0;

  @media screen and (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSizes.title};
    text-align: center;
  }
`;

const ProjectsWrap = styled.div`
  ${({ theme }) => theme.flexing.flexCenter};
  width: 100%;
  justify-content: space-around;
  align-items: space-between;
  background: ${({ theme }) => theme.colors.ui.black};
  flex-wrap: wrap;
`;
