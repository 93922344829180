import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";

const Link = ({ path, name, isMobile, hamBarCloser }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isActive, setIsActive] = useState(false);

  const activeHandle = () => {
    const activate = location.pathname === path;
    setIsActive(activate);
  };

  useEffect(() => {
    activeHandle();
  }, [location.pathname]); // Update isActive when location.pathname changes

  const onClickFunc = () => {
    navigate(path);
    isMobile && hamBarCloser();
  };

  if (!isMobile) {
    return (
      <LinkWrapper onClick={onClickFunc} active={isActive.toString()}>
        {name}
      </LinkWrapper>
    );
  } else {
    return (
      <Links onClick={onClickFunc} active={isActive.toString()}>
        {name}
      </Links>
    );
  }
};

export default Link;

const LinkWrapper = styled.a`
  color: ${({ theme }) => theme.colors.text.inverse};
  ${({ active, theme }) =>
    active === "true" &&
    `
    color:  ${theme.colors.ui.navlink};
  `}
  background: transparent;
  cursor: pointer;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  border: none;
  padding: 0 10px;
  font-size: ${({ theme }) => theme.fontSizes.body};
  font-family: ${({ theme }) => theme.fonts.heading};
  transition: box-shadow 0.3s ease;

  &:hover {
    background: ${({ active, theme }) =>
      active === "true" ? "transparent" : theme.colors.navBgColor};
  }

  @media screen and (max-width: 900px) {
    font-size: ${({ theme }) => theme.fontSizes.button};
  }
`;

const Links = styled.a`
  color: ${({ theme }) => theme.colors.text.inverse};
  font-size: ${({ theme }) => theme.fontSizes.h5};
  ${({ active, theme }) =>
    active === "true" &&
    `
    color:  ${theme.colors.ui.button};
  `}
  margin-bottom: 10px;
  font-family: ${({ theme }) => theme.fonts.heading};
  transition: box-shadow 0.3s ease;

  &:hover {
    background: ${({ active, theme }) =>
      active === "true" ? "transparent" : theme.colors.navBgColor};
    color: ${({ theme }) => theme.colors.brand.inverse};
  }
`;
