import React, { useState } from "react";
import { styled } from "styled-components";
import { BsArrowUpRightSquare } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

const CardHome = (props) => {
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();

  return (
    <MainWrap
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => navigate("/expertise")}
    >
      <ContentWrap>
        <Arrow />
        {props.logo && (
          <IconService isHovered={isHovered}>{props.logo}</IconService>
        )}
        <Service>{props.service}</Service>
        <Description isHovered={isHovered}>{props.text}</Description>
      </ContentWrap>
    </MainWrap>
  );
};

export default CardHome;

const MainWrap = styled.a`
  ${({ theme }) => theme.flexing.columnCenter};
  width: 30%;
  height: 200px;
  margin: 4px;
  background: ${({ theme }) => theme.colors.ui.black};
  cursor: pointer;
  position: relative;
  overflow: hidden;

  &:hover {
    box-shadow: inset 0 -300px 0 -1px ${({ theme }) => theme.colors.ui.button};
    transition: box-shadow 0.5s ease-in-out;
  }

  @media screen and (min-width: 800px) and (max-width: 900px) {
    width: 40%;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    box-shadow: inset 0 -300px 0 -1px ${({ theme }) => theme.colors.ui.button};
  }
`;

const ContentWrap = styled.div`
  ${({ theme }) => theme.flexing.columnCenter};
  height: 80%;
  width: 90%;
`;

const Arrow = styled(BsArrowUpRightSquare)`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: ${({ theme }) => theme.fontSizes.h5};
  color: ${({ theme }) => theme.colors.brand.inverse};
  margin: 0;
`;

const IconService = styled.div`
  height: ${({ isHovered }) => (!isHovered ? "30px" : "0")};
  opacity: ${({ isHovered }) => (!isHovered ? "1" : "0")};
  transition: all 1s ease-in;
  font-size: ${({ theme }) => theme.fontSizes.mobileHeader};
  color: ${({ theme }) => theme.colors.text.inverse};
  margin: 0;
  @media screen and (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSizes.mobileIcon};
  }
`;

const Service = styled.h2`
  font-size: ${({ theme }) => theme.fontSizes.title};
  font-family: ${({ theme }) => theme.fonts.heading};
`;

const Description = styled.p`
  width: 80%;
  font-size: ${({ theme }) => theme.fontSizes.button};
  height: ${({ isHovered }) => (isHovered ? "30px" : "0")};
  opacity: ${({ isHovered }) => (isHovered ? "1" : "0")};
  transition: height 1s ease-in;
  margin-top: 0;
  text-align: center;

  @media screen and (max-width: 767px) {
    opacity: 1;
  }
`;
