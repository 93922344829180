import React from "react";
import styled from "styled-components";
import ServicesListCard from "./services.listcard";
import { specializationData } from "../homeComponents/Specialization.data";

const ServicesList = () => {
  return (
    <MainWrapper>
      <InsideWrapper>
        {specializationData.map((service) => (
          <ServicesListCard key={service.service} data={service} />
        ))}
      </InsideWrapper>
    </MainWrapper>
  );
};

export default ServicesList;

const MainWrapper = styled.div`
  width: 100%;
  min-height: 600px;
  overflow: hidden;
  ${({ theme }) => theme.flexing.flexCenter};
  background: rgb(64, 23, 100);

  @media screen and (max-width: 1025px) and (min-width: 900px) {
    width: 80%;
  }
  @media screen and (max-width: 768px) {
    /* padding: 0px 0; */
  }
`;
const InsideWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin: 50px 0;
  ${({ theme }) => theme.pageSizes.mediaInsideSize};
  display: flex;
  ${
    "" /* justify-content: space-between;
  align-items: space-between; */
  }
  flex-wrap: wrap;
  @media screen and (max-width: 768px) {
    ${({ theme }) => theme.flexing.columnCenter};
    margin: 0;
  }
`;
