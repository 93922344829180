import React, { createContext, useReducer } from "react";

// Define your action types
const SET_MESSAGE = "SET_MESSAGE";

// Create the initial state for the message
const initialMessageState = "";

// Define the reducer function
const messageReducer = (state, action) => {
  switch (action.type) {
    case SET_MESSAGE:
      return action.payload;
    default:
      return state;
  }
};

const MessageContext = createContext();

export const MessageProvider = ({ children }) => {
  // Use useReducer to manage the message state with the reducer
  const [messageState, dispatch] = useReducer(messageReducer, initialMessageState);

  const messageHandler = (value) => {
    if (value.length > 0) {
      // Dispatch an action to set the message
      dispatch({ type: SET_MESSAGE, payload: value });
      
    } else {
      alert('Please enter your message in the box');
    }
  };

  return (
    <MessageContext.Provider
      value={{
        messageState,
        messageHandler,
      }}
    >
      {children}
    </MessageContext.Provider>
  );
};

export default MessageContext;
