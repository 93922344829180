import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { AiTwotonePhone } from "react-icons/ai";
import { GrMail } from "react-icons/gr";
import MessageContext from "../../context/message.context";
import Bg from "../../assets/images/footer/pattern-black@2x.png";

const FooterTop = () => {
  const navigate = useNavigate();
  const [textAreaValue, setTextAreaValue] = useState("");
  const { messageHandler } = useContext(MessageContext);

  const handleTextAreaChange = (event) => {
    setTextAreaValue(event.target.value);
  };

  const messageSender = () => {
    messageHandler(textAreaValue);
    if (textAreaValue.length > 0) {
      navigate("/contact");
    }
    setTextAreaValue("");
  };
  // Function to open the phone dialer
  const openPhoneDialer = () => {
    const phoneNumber = "0700000123";
    window.location.href = `tel:${phoneNumber}`;
  };

  const openEmailClient = () => {
    const email = "info@gndtdigital.com";
    const subject = "Regarding your services";
    const body = "I would like to inquire about your services.";

    window.location.href = `mailto:${email}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
  };
  if (window.location.pathname !== "/contact") {
    return (
      <FooterWrapper>
        <InsideWrapper>
          <LeftContainer>
            <LeftBox>
              <Title>get in touch</Title>
            </LeftBox>
            <RightBox>
              <Header>
                We'd love to hear about <br /> your new project
              </Header>
              <ConnectionWrapper onClick={openPhoneDialer}>
                <PhoneIcon />
                +44 7511549707
              </ConnectionWrapper>
              <ConnectionWrapper onClick={openEmailClient}>
                <MailIcon />
                info@gndtdigital.com
              </ConnectionWrapper>
            </RightBox>
          </LeftContainer>
          <RightContainer>
            <StyledTextArea
              value={textAreaValue}
              onChange={handleTextAreaChange}
              placeholder="Please tell us about your project..."
            />
            <ButtonWrapper>
              <FooterButton onClick={messageSender}>send</FooterButton>
            </ButtonWrapper>
          </RightContainer>
        </InsideWrapper>
      </FooterWrapper>
    );
  }
};

export default FooterTop;

const FooterWrapper = styled.div`
  height: 300px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.ui.button};
  color: ${({ theme }) => theme.colors.text.inverse};
  @media screen and (max-width: 768px) {
    height: 500px;
  }
`;
const InsideWrapper = styled.div`
  width: 1140px;
  height: 200px;
  display: flex;
  padding: 0 3%;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    height: 100%;
  }
`;

const LeftContainer = styled.div`
  flex: 1;
  display: flex;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
  @media screen and (max-width: 768px) {
    margin-top: 20px;
    flex: 0.7;
  }
`;

const RightContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 2%;

  @media screen and (max-width: 768px) {
    padding: 0;
    margin-top: 20px;
  }
`;

const LeftBox = styled.div`
  flex: 1;

  @media screen and (max-width: 1000px) {
    flex: 0.3;
  }
`;

const RightBox = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  font-family: ${({ theme }) => theme.fonts.heading};
  font-weight: 400;
`;

const Title = styled.div`
  width: 100%;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fonts.header};
  font-size: ${({ theme }) => theme.fontSizes.h5};

  @media screen and (max-width: 768px) {
    text-align: center;
  }
`;
const Header = styled.div`
  flex: 0.2;
  font-size: ${({ theme }) => theme.fontSizes.h5};

  @media screen and (max-width: 768px) {
    margin-top: 10px;
    font-size: ${({ theme }) => theme.fontSizes.title};
    text-align: center;
  }
`;
const ConnectionWrapper = styled.div`
  display: flex;
  flex: 0.1;
  margin-top: 10px;
  font-size: ${({ theme }) => theme.fontSizes.caption};
  align-items: center;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    justify-content: center;
  }
`;

const PhoneIcon = styled(AiTwotonePhone)`
  margin-right: 5px;
  font-size: ${({ theme }) => theme.fontSizes.title};
`;

const MailIcon = styled(GrMail)`
  margin-right: 5px;
  font-size: ${({ theme }) => theme.fontSizes.title};
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;

  @media screen and (max-width: 768px) {
    justify-content: center;
  }
`;

const FooterButton = styled.div`
  width: 200px;
  margin-top: 20px;
  text-align: center;
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase;
  background-color: ${({ theme }) => theme.colors.ui.inverse};

  border: 1px solid #fff;

  &:hover {
    background-color: ${({ theme }) => theme.colors.brand.inverse};
    transition: background 0.5s ease-in-out, color 0.5s ease-in-out;
    color: ${({ theme }) => theme.colors.ui.button};
  }
`;
const StyledTextArea = styled.textarea`
  width: 100%;
  height: 100%;
  background: #f8f9fc;
  border: none;
  padding: 10px;
  resize: none;

  @media screen and (max-width: 768px) {
    width: 95%;
  }

  @media screen and (max-width: 768px) {
    height: 150px;
  }
`;
