import React from 'react';
import styled from 'styled-components';
import FooterMain from './footer.main';
import FooterBottom from './footer.bottom';

import FooterTop from './footer.top';


const Footer = () => {

  return (
    <FooterWrapper >
      <FooterTop/>
      <FooterMain />
      <FooterBottom />
    </FooterWrapper>
  );
}

export default Footer;

const FooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 300px;
  background-color:#111;
  justify-content:center;
  align-items:center;
  
@media screen and (max-width:1000px){
  
}
`;
