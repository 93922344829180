import React from "react";
import styled from "styled-components";
import body from "../../assets/images/services/ecommercebody.jpg"

const ServiceDetailedBody=(props)=>{
    return(
       <MainWrapper>
         <BackgroundImage src={props.bodyimg} alt="background" />
         <InfoWrapper>
        <SectionWrap>
          <Answer>{props.text2}</Answer>
          <Answer>{props.text3}</Answer>
        </SectionWrap>
      <CallToAction>{props.action}</CallToAction>
      </InfoWrapper>
       </MainWrapper>
    )
}

export default ServiceDetailedBody

const MainWrapper=styled.div`
   ${({ theme }) => theme.flexing.flexCenter};
   width:100%;
   height:auto;
   color:${({theme})=>theme.colors.brand.inverse};
   text-align:center;
   background:${({theme})=>theme.colors.ui.black};
   position:relative;
`

const BackgroundImage = styled.img`
  width: 100%;
  height: 750px;
  object-fit: cover;
  opacity: 0.2;

  @media screen and (max-width: 678px) {
    display:none;
  }
`;

const InfoWrapper=styled.div`
   ${({ theme }) => theme.flexing.columnCenter}
   width: 80%;
   height: 100%;
   position: absolute;
   font-family:${({ theme }) => theme.fonts.heading};

   @media screen and (max-width: 678px) {
    position:relative;
    padding-bottom:80px;
  }
`

const SectionWrap = styled.div`
  ${({ theme }) => theme.flexing.columnCenter}
  width: 100%;
  margin: 50px 0;
`;

const Answer=styled.div`
   font-size:${({theme})=>theme.fontSizes.body};
   padding-top:10px;
`

const CallToAction=styled.div`
   font-size:${({theme})=>theme.fontSizes.body};
   padding-top:50px;
   text-transform:uppercase;
`