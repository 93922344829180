import mycinema from "../../assets/images/mycinema.jpg";
import hairsaloon from "../../assets/images/hairsaloon.jpg";
import cafe from "../../assets/images/cafe.jpg";
import theatre from "../../assets/images/theatreclass.jpg";
import essenza from "../../assets/images/essenza.jpg";
import wrappamondo from "../../assets/images/projects/wrappamondo.jpg";

export const works = [
  {
    background: mycinema,
    title: "MyCinema",
    projecttype: "eCommerce Web App",
    projectname: "Engineering Excels",
  },
  {
    background: hairsaloon,
    title: "Iris Hair",
    projecttype: "Interactive Planner with Boking Engine",
    projectname: "Lorem Sit Amet",
  },
  {
    background: cafe,
    title: "Seventh Heaven",
    projecttype: "Web App, Sales Management System",
    projectname: "Awe",
  },
  {
    background: theatre,
    title: "Theatre Club",
    projecttype: "Web App, Sales Management System",
    projectname: "Awe",
  },
  {
    background: essenza,
    title: "Essenza",
    projecttype: "Branding, Sales Management System",
    projectname: "Awe",
  },
  {
    background: wrappamondo,
    title: "Wrappamondo",
    projecttype: "Branding and marketing",
    projectname: "Awe",
  },
];
