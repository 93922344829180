import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const FooterBottom = () => {
  const navigate = useNavigate();

  const navigateFunc = (link) => {
    navigate(link);
  };

  return (
    <FooterBottomWrapper>
      <FooterBox>
        <Wrapper>
          <LeftBox>
            <SubText>
              © GNDTDIGITAL 2024. ALL RIGHTS RESERVED.
              <br />
              <span>web design london</span>
              <br />
              <span>web developers london</span>
            </SubText>
          </LeftBox>
          <RightBox>
            {/* <Box>vat:143260345</Box> */}
            <Box>company no. 15053958</Box>
            <Box>
              <Link
                onClick={() => {
                  navigate("/terms");
                }}
              >
                terms & conditions
              </Link>
            </Box>
            <Box>
              <Link
                onClick={() => {
                  navigate("/privacy");
                }}
              >
                privacy policy
              </Link>
            </Box>
          </RightBox>
        </Wrapper>
      </FooterBox>
    </FooterBottomWrapper>
  );
};

export default FooterBottom;

const FooterBottomWrapper = styled.div`
  color: ${({ theme }) => theme.colors.text.secondary};
  height: 120px;
  width: 100%;
  /* background-color: ${({ theme }) => theme.colors.ui.primary}; */
  @media screen and (max-width: 768px) {
    height: 150px;
    ${({ theme }) => theme.flexing.flexCenter};
    padding-bottom: 50px;
  }
`;

const FooterBox = styled.div`
  width: 100%;
  ${({ theme }) => theme.flexing.flexCenter};
  height: 100%;
  margin-left: auto;
  margin-right: auto;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 50%;
  padding: 0 3%;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    height: 100%;
    padding: 0 5%;
  }
`;

const LeftBox = styled.div`
  flex: 0.5;
`;

const RightBox = styled.div`
  flex: 1.5;
  display: flex;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    margin-top: 10px;
  }
`;

const SubText = styled.span`
  margin-top: 2px;
  color: ${({ theme }) => theme.colors.text.secondary};
  font-size: ${({ theme }) => theme.fontSizes.caption};
  font-family: ${({ theme }) => theme.fonts.heading};
  display: block;
  span {
    margin-top: 0;
    text-transform: uppercase;
  }
`;

const Box = styled.div`
  flex: 0.33;
  height: 100%;
  display: flex;
  justify-content: center;
  font-size: ${({ theme }) => theme.fontSizes.caption};
  font-family: ${({ theme }) => theme.fonts.heading};
  text-transform: uppercase;
  @media screen and (max-width: 768px) {
    justify-content: flex-start;
  }
`;

const Link = styled.a`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.text.secondary};
`;
