import React from "react";
import styled from "styled-components";

const Section = ({ data }) => {
  return (
    <SectionWrapper>
      <Title>{data.title}</Title>
      <Header>{data.header}</Header>
      <Par>{data.p}</Par>
      <Ordered>
        {data.ol?.map((li, index) => (
          <li key={index}>{li}</li>
        ))}
      </Ordered>
      <Par>{data.p2}</Par>
    </SectionWrapper>
  );
};

export default Section;

const SectionWrapper = styled.div`
  margin: 0 10%;
`;
const Title = styled.h1`
  font-size: ${({ theme }) => theme.fontSizes.h3};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  @media screen and (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSizes.h4};
    text-align: left;
  }
`;

const Header = styled.h4`
  font-size: ${({ theme }) => theme.fontSizes.body};
`;
const Par = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.body};
  white-space: pre-line;
  @media screen and (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSizes.button};
  }
`;

const Ordered = styled.ol`
  list-style-type: disc;
  padding-left: 1em;
  li {
    margin: 0.5em 0;
    @media screen and (max-width: 768px) {
      font-size: ${({ theme }) => theme.fontSizes.button};
    }
  }
`;
