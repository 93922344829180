import React from "react";
import styled from "styled-components";
import homeaboutus from "../../assets/images/homeaboutus.jpg";
import { useNavigate } from "react-router-dom";
import { BsArrowRightShort } from "react-icons/bs";

const HomeAboutUs = () => {
  const navigate = useNavigate();

  return (
    <MainWrap>
      <Box>
        <Descr>
          We're seasoned experts in digital products, providing tailored
          top-tier solutions to meet your unique needs, with mastery across
          product development and optimisation.
        </Descr>
        <Link onClick={() => navigate("/about")}>
          <ButtonText>About Us</ButtonText>
          <ArrowIcon />
        </Link>
      </Box>
    </MainWrap>
  );
};

export default HomeAboutUs;

const MainWrap = styled.div`
  ${({ theme }) => theme.flexing.columnCenter};
  position: relative;
  width: 100%;
  height: 600px;
  background-image: url(${homeaboutus});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  @media screen and (max-width: 768px) {
    height: 400px;
  }
`;

const Box = styled.div`
  width: 30%;
  height: 50%;
  background: ${({ theme }) => theme.colors.ui.button};
  opacity: 0.9;
  position: absolute;
  left: 0;
  bottom: 0;
  ${({ theme }) => theme.flexing.columnCenter};

  @media screen and (min-width: 768px) and (max-width: 900px) {
    width: 100%;
    height: 40%;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

const Descr = styled.div`
  width: 90%;
  font-size: ${({ theme }) => theme.fontSizes.title};
  font-family: ${({ theme }) => theme.fonts.heading};

  @media screen and (max-width: 768px) {
    margin-top: 20px;
    font-size: ${({ theme }) => theme.fontSizes.button};
  }
`;

const Link = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  width: 90%;
  margin: 20px 0;
`;

const ButtonText = styled.h6`
  margin: 0;
  padding: 0;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes.title};
  text-transform: uppercase;

  &:hover {
    padding-right: 10px;
    transition: all 1s;
  }

  @media screen and (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSizes.button};
  }
`;

const ArrowIcon = styled(BsArrowRightShort)`
  color: ${({ theme }) => theme.colors.text.inverse};
  font-size: ${({ theme }) => theme.fontSizes.h4};

  &:hover {
    padding-left: 10px;
    transition: all 1s;
  }
`;
