import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Button from "../button/Button";

const ServicesBody = ({
  reverse,
  imgData,
  title,
  info,
  color,
  buttonTheme,
  buttonText,
}) => {
  const navigate = useNavigate();
  return (
    <MainWrapper color={color}>
      <MainInsideWrapper reverse={reverse}>
        <InfoContainer>
          <HeaderWrapper>
            <Header color={color}>
              <h4>{title}</h4>
            </Header>
            <ParContainer color={color}>
              {Array.isArray(info) ? (
                info.map((paragraph, idx) => <p key={idx}>{paragraph}</p>)
              ) : (
                <p>{info}</p>
              )}
            </ParContainer>
            <ContactContainer>
              <Button text={buttonText} buttonTheme={buttonTheme} />
            </ContactContainer>
          </HeaderWrapper>
        </InfoContainer>
        <ImageContainer>
          <img src={imgData} />
        </ImageContainer>
      </MainInsideWrapper>
    </MainWrapper>
  );
};

export default ServicesBody;

const MainWrapper = styled.div`
  width: 100%;
  min-height: 600px;

  ${({ theme }) => theme.flexing.flexCenter};
  background: ${({ color, theme }) =>
    color ? theme.colors.proposal.expertise : theme.colors.brand.inverse};

  @media screen and (max-width: 768px) {
    padding: 50px 0;
  }
`;
const MainInsideWrapper = styled.div`
  width: 100%;
  height: 100%;

  ${({ theme }) => theme.pageSizes.mediaInsideSize};
  ${({ theme }) => theme.flexing.flexCenter};
  flex-direction: ${({ reverse }) =>
    reverse === true ? "row-reverse" : "row"};

  @media screen and (max-width: 768px) {
    ${({ theme }) => theme.flexing.columnCenter};
  }
`;
const InfoContainer = styled.div`
  flex: 1;
  margin: 0 10px;
  height: 100%;
`;

const ImageContainer = styled.div`
  flex: 1;
  margin: 0 10px;
  height: 100%;
  border-radius: 7px;
  overflow: hidden;
  ${({ theme }) => theme.flexing.flexCenter};
  img {
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    padding: 10%;
    img {
      width: 90%;
    }
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Header = styled.div`
  flex: 0.3;
  font-size: 35px;

  text-transform: uppercase;
  font-family: "REM", sans-serif;

  font-weight: 700;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  h4 {
    margin: 0;
    color: ${({ color, theme }) =>
      color ? theme.colors.ui.button : theme.colors.text.black};
  }

  @media screen and (max-width: 400px) {
    font-size: 25px;
  }
`;

const ParContainer = styled.div`
  flex: 0.3;
  color: ${({ color, theme }) =>
    color ? theme.colors.text.inverse : theme.colors.text.black};
  font-family: ${({ theme }) => theme.fonts.heading};
  @media screen and (max-width: 1500px) {
    font-size: 14px;
  }
`;

const ContactContainer = styled.div`
  flex: 0.4;
`;
