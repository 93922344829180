import { colors } from "./colors";
import { space, lineHeights } from "./spacing";
import { sizes, pageSizes, connectSizes } from "./sizes";
import { fonts, fontWeights, fontSizes } from "./fonts";
import { flexing } from "./flexbox";

export const theme = {
  colors,
  space,
  lineHeights,
  sizes,
  pageSizes,
  connectSizes,
  fonts,
  fontSizes,
  fontWeights,
  flexing,
};
