import React from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";

const CardProject = (props) => {
  const navigate = useNavigate();

  return (
    <MainWrap
      onClick={() => {
        navigate(`/project/${props.name}`);
      }}
      image={props.image}
    >
        <InfoWrap>
          <Tech>{props.tech}</Tech>
          <Name> {props.name}</Name>
        </InfoWrap>
    </MainWrap>
  );
};

export default CardProject;

const MainWrap = styled.a`
  ${({ theme }) => theme.flexing.columnCenter};
  width: 50%;
  height:400px;
  transition: opacity 0.3s;
  background-image: ${({ image }) => `url(${image})`}; 
  background-repeat: no-repeat;
  background-size:cover;
  cursor: pointer;
  position:relative;

  @media screen and (min-width: 800px) and (max-width: 900px) {
    width: 50%;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  &:hover {
    opacity: 0.9;
  }
`;

const InfoWrap = styled.div`
  opacity:0;
  width:100%;
  position: absolute;
  bottom: 0;
  color: ${({ theme }) => theme.colors.text.inverse};
  font-family: ${({ theme }) => theme.fonts.heading};
  background: linear-gradient(to right, ${({ theme }) => theme.colors.ui.button}, ${({ theme }) => theme.colors.ui.comopac}); 
  transition:opacity 0.5s;

  ${MainWrap}:hover & {
    opacity: 1;
  }

  @media screen and (max-width: 767px) {
    opacity:1;
  }
`;

const Tech = styled.div`
  ${'' /* text-transform: uppercase; */}
  padding:20px 0 0 40px;;
  font-size: ${({ theme }) => theme.fontSizes.button};
`;

const Name = styled.div`
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  padding:0 0 40px 40px;
  font-size: ${({ theme }) => theme.fontSizes.h5};
`;
