import React from "react";
import styled from "styled-components";
import ServicesHeader from "../components/servicesComponents/Services.header.component";
import ServicesBody from "../components/servicesComponents/Services.body.component";
import {
  servicesData,
  chooseUs,
} from "../components/servicesComponents/services.data";
import CardWrapperComponent from "../components/cardWrapperComponent/cardwrapper.component";
import { servicesCardsData } from "../components/servicesComponents/services.data";
const Expertise = () => {
  return (
    <ExpertiseWrapper>
      <ExpertiseInsideWrapper>
        <ServicesHeader />
        {servicesData.map((data) => (
          <ServicesBody
            key={data.id}
            reverse={data.reverse}
            imgData={data.img}
            title={data.title}
            info={data.info}
            buttonText={"Find out more"}
            buttonTheme="light"
          />
        ))}
        {/* <ServicesCardWrapper /> */}
        <CardWrapperComponent
          cardData={servicesCardsData}
          title="Our Case Studies"
          header1="What we`ve done"
          header2="recently"
          card="services"
          buttonColor="#000"
        />
        <ServicesBody
          key={chooseUs.data}
          reverse={chooseUs.reverse}
          imgData={chooseUs.img}
          title={chooseUs.title}
          info={chooseUs.info}
          color={chooseUs.color}
          buttonText={"get in touch"}
          buttonTheme="dark"
        />
      </ExpertiseInsideWrapper>
    </ExpertiseWrapper>
  );
};

export default Expertise;

const ExpertiseWrapper = styled.div`
  margin-top: 70px;
  min-height: 600px;
  ${({ theme }) => theme.pageSizes.media};
`;

const ExpertiseInsideWrapper = styled.div`
  width: 100%;
  min-height: 600px;
  background: rgb(64, 23, 100);
  /* background: #002722; */
  color: #fff;
  ${({ theme }) => theme.flexing.columnCenter};
`;
