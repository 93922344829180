import styled from "styled-components";
import ServicesHeader from "../components/newServices/services.header";
import ServicesList from "../components/newServices/services.list";
import CardWrapperComponent from "../components/cardWrapperComponent/cardwrapper.component";
import { works } from "../components/workComponents/WorkData";
import BackgroundImage from "../assets/images/footer/pattern-black@2x.png";

const ServicesPage = () => {
  return (
    <ServicesWrapper>
      <ServicesHeader />
      <ServicesList />
      <WhiteBackground>
        <CardWrapperComponent
          header1="OUR LATEST PROJECTS"
          card="services"
          cardData={works}
          page={"services"}
        />
      </WhiteBackground>
    </ServicesWrapper>
  );
};

export default ServicesPage;

const ServicesWrapper = styled.div`
  width: 100%;
  padding-top: 110px;
  min-height: 800px;
  background: rgb(64, 23, 100);
  color: #fff;
  ${({ theme }) => theme.flexing.columnCenter}
`;

const WhiteBackground = styled.div`
  width: 100%;
  ${({ theme }) => theme.flexing.flexCenter};
  background: rgb(64, 23, 100);
  /* background-image:url(${BackgroundImage});
  background-size: cover; 
  background-repeat: no-repeat;  */
`;
