export const fonts = {
  heading: `"Poppins",sans-serif`,
  header: `'REM', sans-serif;`,
};

export const fontWeights = {
  regular: 400,
  medium: 500,
  bold: 700,
};

export const fontSizes = {
  caption: "12px",
  button: "14px",
  body: "16px",
  title: "20px",
  mobileHeader: "30px",
  mobileIcon: "40px",
  h5: "24px",
  h4: "34px",
  h3: "45px",
  h2: "56px",
  h1: "112px",
  header: "75px",
};
