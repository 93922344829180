import React, { useRef, useState, useContext, useEffect } from "react";
import styled from "styled-components";
import emailjs from "emailjs-com";
import ReCAPTCHA from "react-google-recaptcha";
import Modal from "./Modal";

import MessageContext from "../../context/message.context";
const recaptchaRef = React.createRef();

const ContactComponent = () => {
  const { messageState } = useContext(MessageContext);
  const form = useRef();
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [textAreaValue, setTextAreaValue] = useState(messageState);
  const siteKey = process.env.REACT_APP_SITE_KEY;

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    console.log("changed");
    setTextAreaValue(messageState);
  }, [messageState]);
  const handleTextAreaChange = (event) => {
    setTextAreaValue(event.target.value);
  };

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_8m41rmf",
        "template_vicf3cy",
        form.current,
        "3JQvzNEUNj2YnjXes"
      )
      .then(
        (result) => {
          console.log(result.text);
          setIsModalOpen(true); // Open modal on success
          setTextAreaValue(""); // Reset the text area value
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  return (
    <ContactWrapper>
      {isModalOpen && (
        <Modal
          message="Email Sent Successfully"
          onClose={() => {
            setIsModalOpen(false);
          }}
        />
      )}
      <HeaderWrapper>Tell us about your project.</HeaderWrapper>
      <MainContactBox>
        <TitleContainer>Send a message</TitleContainer>
        <FormWrapper ref={form} onSubmit={sendEmail}>
          <InputWrapper>
            <InputContainer>
              <InputLabel>Your Name *</InputLabel>
              <FormInput
                required
                type="text"
                name="name"
                placeholder="Your Name"
              />
            </InputContainer>
            <InputContainer>
              <InputLabel>Company Name</InputLabel>
              <FormInput
                type="text"
                name="company"
                placeholder="Company Name"
              />
            </InputContainer>
          </InputWrapper>

          <InputWrapper>
            <InputContainer>
              <InputLabel>Email *</InputLabel>
              <FormInput
                required
                type="email"
                name="email"
                placeholder="Email"
              />
            </InputContainer>
            <InputContainer>
              <InputLabel>Phone Number *</InputLabel>
              <FormInput
                required
                type="number"
                name="phone"
                placeholder="Phone Number"
              />
            </InputContainer>
          </InputWrapper>

          <TextAreaWrapper>
            <InputContainer>
              <TextAreaInputLabel>Message *</TextAreaInputLabel>
              <CenteredTextArea>
                <FormTextarea
                  required
                  name="message"
                  value={textAreaValue}
                  onChange={handleTextAreaChange}
                  placeholder="Please give us as much details as possible"
                />
              </CenteredTextArea>
            </InputContainer>
          </TextAreaWrapper>
          <InputWrapper style={{ margin: "20px 0" }}>
            {/* <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={siteKey}
              onChange={onChange}
            /> */}
          </InputWrapper>
          <InputWrapper>
            <FormButton type="submit" value="Send" />
          </InputWrapper>
        </FormWrapper>
      </MainContactBox>
    </ContactWrapper>
  );
};

export default ContactComponent;

const ContactWrapper = styled.div`
  ${({ theme }) => theme.pageSizes.mediaInsideSize};
  ${({ theme }) => theme.flexing.columnCenter};
  width: 100%;
  height: 900px;
  margin-left: auto;
  margin-right: auto;
  font-family: ${({ theme }) => theme.fonts.heading};

  @media screen and (min-width: 800px) and (max-width: 1200px) {
    margin: 100px auto;
  }
`;

const HeaderWrapper = styled.div`
  flex: 0.3;
  color: ${({ theme }) => theme.colors.text.inverse};
  ${({ theme }) => theme.flexing.columnCenter};
  font-size: ${({ theme }) => theme.fontSizes.h2};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  padding-top: 50px;

  @media screen and (max-width: 768px) {
    text-align: center;
    font-size: ${({ theme }) => theme.fontSizes.h5};
    flex: 0.1;
  }
`;

const MainContactBox = styled.div`
  flex: 0.7;
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const TitleContainer = styled.div`
  flex: 0.1;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fonts.header};
  span {
    width: 7px;
    height: 7px;
    background-color: ${({ theme }) => theme.colors.text.inverse};
    margin-right: 10px;
  }
  @media screen and (max-width: 768px) {
    margin-left: 10px;
  }
`;
const FormWrapper = styled.form`
  flex: 0.9;
  ${({ theme }) => theme.flexing.columnCenter};
`;

const InputWrapper = styled.div`
  flex: 0.2;
  width: 100%;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const TextAreaWrapper = styled.div`
  flex: 0.4;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const CenteredTextArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
const TextAreaInputLabel = styled.label`
  height: 20px;
  font-size: ${({ theme }) => theme.fontSizes.button};
  margin: 5px 0;
  color: ${({ theme }) => theme.colors.text.inverse};
  margin-left: 20px;
  font-family: ${({ theme }) => theme.fonts.header};
`;
const FormInput = styled.input`
  flex: 0.4;
  height: 30px;
  padding: 10px 0 10px 20px;
  margin: 0 20px;
  background: ${({ theme }) => theme.colors.brand.inverse};
  border: none;
  color: ${({ theme }) => theme.colors.text.primary};
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-text-fill-color: ${({ theme }) =>
      theme.colors.text.primary} !important;
    transition: background-color 5000s ease-in-out 0s;
  }
`;

const FormTextarea = styled.textarea`
  width: 95%;
  margin: 0 20px;
  height: 7rem;
  background: ${({ theme }) => theme.colors.brand.inverse};
  padding: 20px;
  border: none;
  color: ${({ theme }) => theme.colors.text.primary};
`;
const FormButton = styled.input`
  width: 30%;
  height: 3rem;
  color: ${({ theme }) => theme.colors.brand.inverse};
  background-color: transparent;
  border: 2px solid ${({ theme }) => theme.colors.text.inverse};
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSizes.button};
  text-transform: uppercase;
  font-weight: bold;
  font-family: ${({ theme }) => theme.fonts.header};
  &:hover {
    background-color: ${({ theme }) => theme.colors.brand.primary};
    transition: background-color 0.5s ease-in-out;
  }
`;
const InputLabel = styled.label`
  height: 20px;
  font-size: ${({ theme }) => theme.fontSizes.caption};
  margin: 5px 0;
  color: ${({ theme }) => theme.colors.text.inverse};
  margin-left: 20px;
  font-family: ${({ theme }) => theme.fonts.header};
`;
