import React from "react";
import styled from "styled-components";
import AboutHeader from "../components/AboutComponents/about.header.component";
import AboutMain from "../components/AboutComponents/about.main.component";
import AboutComponent from "../components/AboutComponents/about.about.component";
import CardWrapperComponent from "../components/cardWrapperComponent/cardwrapper.component";
import { teamData } from "../components/AboutComponents/teamData";
const About = () => {
  return (
    <AboutWrapper>
      <AboutInsideWrapper>
        <AboutHeader />
        <AboutMain />
        <AboutComponent />
        <CardWrapperWrap>
          {/* <CardWrapperComponent
            cardData={teamData}
            title="Our Team"
            header1="Our Team"
            header2=""
            card="about"
            buttonColor="#ba1193"
            page={"about"}
          /> */}
        </CardWrapperWrap>
      </AboutInsideWrapper>
    </AboutWrapper>
  );
};

export default About;

const AboutWrapper = styled.div`
  min-height: 600px;
  ${({ theme }) => theme.pageSizes.media};
`;

const AboutInsideWrapper = styled.div`
  width: 100%;
  /* background: rgb(2, 0, 36); */
  background: ${({ theme }) => theme.colors.gradients.about};
  min-height: 600px;
  ${({ theme }) => theme.flexing.columnCenter};
`;

const CardWrapperWrap = styled.div`
  ${({ theme }) => theme.pageSizes.mediaInsideSize}
  margin-bottom:100px;
`;
