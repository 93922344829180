import styled from "styled-components";
import { useState, useEffect } from "react";

const ServicesHeader = () => {
  const [load, setLoad] = useState(false);

  useEffect(() => {
    setLoad(true);
  }, []);

  return (
    <HeaderWrapper>
      <InsideWrapper>
        <TitleEffect load={load}>
          <OurWorksOne>our</OurWorksOne>
          <OurWorksTwo>services</OurWorksTwo>
        </TitleEffect>
      </InsideWrapper>
    </HeaderWrapper>
  );
};

export default ServicesHeader;

const HeaderWrapper = styled.div`
  width: 100%;
  height: 350px;
  ${({ theme }) => theme.flexing.flexCenter};

  @media screen and (max-width: 768px) {
    height: 300px;
  }
`;

const InsideWrapper = styled.div`
  width: 100%;
  height: 100%;
  ${({ theme }) => theme.pageSizes.mediaInsideSize};
`;

const TitleEffect = styled.div`
  width: 80%;
  opacity: ${({ load }) => (load ? 1 : 0)};
  transform: translateY(${({ load }) => (load ? "100px" : "150px")});
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;

  @media screen and (max-width: 768px) {
    transform: translateY(${({ load }) => (load ? "20px" : "70px")});
    padding: 60px 20px;
  }
`;

const OurWorksOne = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.header};
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fonts.header};
  color: ${({ theme }) => theme.colors.ui.navlink};
  text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
  @media screen and (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSizes.h2};
  }
  @media screen and (max-width: 600px) {
    font-size: ${({ theme }) => theme.fontSizes.h3};
  }
`;

const OurWorksTwo = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.header};
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fonts.header};
  margin: 0;
  color: ${({ theme }) => theme.colors.text.inverse};

  @media screen and (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSizes.h2};
  }
  @media screen and (max-width: 600px) {
    font-size: ${({ theme }) => theme.fontSizes.h3};
  }
`;
