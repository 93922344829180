export const TermsDataEng = [
  { title: `Terms and Conditions` },
  {
    header: "Definitions",
    p: `'You', 'client' or 'clients' refers to the person and/or the business that we are working with.

    'We', 'GNDTDIGITAL', 'us' and 'our' all refer to GNDTDIGITAL.
    
    A live website means any website which has been completed to the client's satisfaction and has been uploaded to the Internet to a web address of the client's choice.
    
    The client's approval for work to commence shall be deemed a contractual agreement between the client and us.
    
    Important Approval for the work to commence, and payment of the advance fee, indicates that the client accepts the terms and conditions outlined in this document.`,
  },
  {
    header: "Order Agreement",
    p: `An order agreement is a written or verbal contract, made by telephone, email or verbally between us and the client. Once an order agreement is in place, we will complete all work covered by the scope agreed in your quotation.`,
  },
  {
    header: "Contract with us",
    p: `As the client, you can accept our offer either verbally or in writing and when this is done we can begin the work on your project. Your intent to enter a contract with us is followed by the payment of your initial deposit, which formalises acceptance of our quotation and agreement to the work commencing.`,
  },
  {
    header: "Our commitment",
    p: `Upon acceptance of our offer, we will deliver the services identified in your quotation in a professional and timely manner. We will keep you regularly informed throughout the process of design and production of your project and we will make every effort to meet any projected delivery schedules. Our aim is to ensure you are happy with our work and we will work with you to ensure this is the case.`,
  },
  {
    header: "Liability",
    p: `We will not be liable for any costs incurred, compensation or loss of earnings due to work carried out for or on behalf of the client, or due to delays in the completion of a website where we have been delayed through no fault of their own, for example; when a client is required to provide specific content, or information, relative to the design and fails to meet agreed deadlines, fails to make key decisions relative to staged design work, providing feedback or has requested excessive changes to agreed design layout and content.`,
  },
  {
    header: "Guarantee",
    p: `We guarantee any work for 6 months from the go live date.  In the unlikely event that any errors or issues arise within this time, these will be corrected at no cost to the client.  After the guarantee period, any errors or faults will be fixed at our standard hourly rate.`,
  },
  {
    header: "Payments",
    p: "The client is billed at the stages of development listed below unless stated otherwise:",
    ol: [
      "25% non­refundable deposit upon placement of the order",
      "25% following sign off of the project design phase",
      "25% after all web development is complete and the website is scheduled to be put onto a test server",
      "25% before the website is uploaded to the live server",
    ],
  },
  {
    header: `Late Payments`,
    p: `We require that all outstanding payments are made within 30 days of receipt of invoice. For invoices not settled within the agreed credit terms, we reserve the right to charge 5% interest on the overdue debt per month and any administration fees to cover the debt recovery costs incurred.

    For any outstanding invoices that remain unpaid, we reserve the right to terminate the website and hosting temporarily and/or withhold any handover until all payments have been met.  Upon payment, all website functionality will be restored immediately.`,
  },
  {
    header: `Out of pocket expenses`,
    p: `Fees for professional services are not included within the project quotation. This includes third party software, photography licenses and printing.`,
  },
  {
    header: `Cancellation`,
    p: `Should the client wish to cancel at any point during the process they shall remain liable for the work that is outlined within the quotation and shall be invoiced accordingly.

    We will class a project as being cancelled if any or all of the below occurs:`,
    ol: [
      `The client fails to provide content which prevents the project from being completed, or prevents us from continuing with the development process`,
      `The client fails to respond to emails or any form of correspondence from us`,
      `The client is not contactable for more than 6 weeks`,
    ],
    p2: `Non payment will result in legal action being taken if necessary and the client will incur any fees involved in recovering the outstanding debt.

    If a project is cancelled at an intermediate stage, we reserves the right to charge the client for all work carried out to date calculated at a standard rate of £80 per hour.
    
    If a project is cancelled more than 50% of the way through, the client is liable to pay what was quoted for the project at the start plus any additional work carried out and quoted for throughout the project.`,
  },
  {
    header: `Website Live Date`,
    p: `Once you have approved your website, and any outstanding balance of payment has been received, your website will be launched live onto the Internet, providing we have been supplied with the necessary information required below.`,
  },
  {
    header: `Website Copyright`,
    p: `We holds the right to retain full copyright of all websites designed. Our designs must not be copied or used in any way by the client or any third party.

    We retains full ownership of the project and all assets until all payments have been made in accordance with the original quotation.  Once all payments have been made, we will transfer full ownership of all assets and files to the client.`,
  },
  {
    header: `Client responsibilities with regards to Copyright`,
    p: `In situations where the client provides images, text, animations or any other content for their website they are legally responsible for ensuring that this material does not infringe any copyright. Certain images provided by us in the construction of the website may have been purchased under license from stock image suppliers. These images are generally only licensed for use on a single website and may not be used in publicity material.

    The website owner is legally responsible for ensuring that this does not happen. If you wish to use any images from the site for other purposes please contact us for clarification.`,
  },
  {
    header: `Existing domain names`,
    p: `Where a domain name has already been purchased by an individual or agent, other than us, and in cases where a client's new website is to be hosted on a different server, it is crucial that we are given full access to the aforementioned domain name control panel. This enables us to direct your domain name at the appropriate new server, to which it will be uploaded, or provide you with the ability to login to your control panel yourself and to make the appropriate domain name server changes that will be required. If your website is unable to be uploaded because of a failure to provide this information to us, we will not be held liable and, in such cases, are deemed to have carried out our contractual agreement with you upon which payment is then required.`,
  },
  {
    header: `Existing web hosting`,
    p: `If a client already has a website host and requires their new design to remain with them, they must provide us with full access to the FTP server so we can upload the website upon completion. If the client fails to provide us with the access to the FTP server, which results in us being unable to upload the website, we will be deemed to have carried out all contractual agreements with the client and payment will be required.`,
  },
  {
    header: `Additional costs`,
    p: `All our quotes are based on an estimate of costs agreed at the project scope phase and created from the information provided to us. Any additional work, above that specified in the initial quote, will require our hourly rate of £80 to be payable and an estimate will be provided in writing prior to additional work being undertaken.

    Once the following stages are approved by yourself either verbally or in writing and the following stage begins, any changes requested to previous stages will be charged for. These stages are:`,
    ol: [
      `Design stage`,
      `Initial Development stage`,
      `Testing and minor amendments such as text edits`,
      `Project Delivery`,
    ],
    p2: `We cannot guarantee the exact timescale for completion of the website at the outset until we are provided with all the information and content required. However we will endeavour to be as accurate as we can in the provision of an estimated project completion date. We request that all clients are timely and professional in providing us with the correct information required to meet the project scope.`,
  },
  {
    header: `Advertising Notice`,
    p: `We reserves the right to include a small 'site by GNDTDIGITAL' link to the foot of each designed website and/or within the hidden META tags created within the design format.`,
  },
  {
    header: `Search Engine Optimisation`,
    p: `We do everything possible to ensure that all our websites are search engine friendly and are compatible with the latest best practice guidelines wherever possible.`,
  },
  {
    header: `Website Hosting`,
    p: `Where website hosting is provided, we use a third party web host and, as such, cannot be held liable for any website down time, restricted access or loss of earnings resulting from server maintenance, hacking or similar unforeseen circumstances. We only use trusted Third Party Hosts and so such occurrences are extremely rare.`,
  },
  {
    header: `Approval`,
    p: `By agreeing to these terms and conditions your statutory rights are not affected. We reserves the right to change or modify any of these terms or conditions at any time. Should clarification of any of the above be required please contact us on info@gndtdigital.com.`,
  },
];
