export const colors = {
  brand: {
    primary: "rgba(197, 47, 83, 1)",
    inverse: "#FFFFFF",
  },
  ui: {
    primary: "#111724",
    secondary: " #25283d",
    black: "#000000",
    button: "rgba(197, 47, 83, 1)",
    navlink: "rgba(245,42,113,1)",
    comopac: "rgba(197, 47, 83, 0.7)",
  },
  text: {
    primary: "#262626",
    secondary: "#757575",
    inverse: "#FFFFFF",
    gndtfrom: "rgb(228,58,63)",
    black: "#000000",
  },
  proposal: {
    contact: `#052b3e`,
    work: `#310335`,
    about: `#200319`,
    services: `#002722`,
  },

  buttonTheme: {
    darkTheme:
      "border:1px solid #fff; background:#000;&:hover {background:rgba(197, 47, 83, 1)}",
    lightTheme:
      "border:1px solid #000;color:#000; background:#fff;&:hover {background:rgba(197, 47, 83, 1);color:#fff}",
    coloredTheme:
      "border:1px solid #fff;color:#fff; background:#000;&:hover {background:rgba(197,47,83,1);}",
  },

  navBgColor:
    "-webkit-box-shadow: 0 -15px 2px -2px rgba(197,47,83,1) inset;-moz-box-shadow: 0 -15px 2px -2px rgba(197, 47, 83, 1) inset;box-shadow: 0 -15px 2px -2px rgba(197, 47, 83, 1) inset;",
  gradients: {
    about: `radial-gradient(
      circle,
      rgba(2, 0, 36, 1) 2%,
      rgba(32, 3, 25, 0.6699054621848739) 78%
    )`,
  },
};
