import React from "react";
import styled, {keyframes} from "styled-components";
import logofades from "../assets/images/logofades.png"

const MockPage = () => {
  return (
    <MainDiv>
      <Logo src={logofades} alt="logo" />
      <ComingSoon>Coming soon</ComingSoon>
    </MainDiv>
  );
};

export default MockPage;

const MainDiv = styled.div`
  color: ${({ theme }) => theme.colors.ui.primary};;
  h3 {
    font-size: ${({ theme }) => theme.fontSizes.h3};
  }
  h5 {
    font-size: ${({ theme }) => theme.fontSizes.h5};
  }
  ${({ theme }) => theme.pageSizes.media};
  ${({ theme }) => theme.flexing.columnCenter};
  background: ${({ theme }) => theme.colors.text.inverse};
  height: 100vh;
`;


const fadeInAnimation = keyframes`
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  40% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const ComingSoon=styled.h5`
    animation: ${fadeInAnimation} 10s ease-in-out;
`

const Logo=styled.img`
  width:7rem;
  animation: ${fadeInAnimation} 10s ease-in-out;
`