import React from "react";
import styled from "styled-components";
import ProjectHeaderDesign1 from "./projectdesign1.header.component";
import ProjectDesign1Brief from "./projectdesign.brief.component";

const ProjectDesign1 = ({ data }) => {
  return (
    <MainWrapper>
      <ProjectHeaderDesign1 data={data} />

      <InsideWrapper>
        <ProjectDesign1Brief data={data} />
      </InsideWrapper>
      <Screenshots>
        <Image src={data.screenshot} />
      </Screenshots>
    </MainWrapper>
  );
};

export default ProjectDesign1;

const MainWrapper = styled.div`
  width: 100%;
  ${({ theme }) => theme.flexing.columnCenter};
  background: #000;
  margin-bottom: 20px;
`;

const InsideWrapper = styled.div`
  width: 100%;
  min-height: 200px;
  /* background: #000; */
  ${({ theme }) => theme.flexing.columnCenter};
  ${({ theme }) => theme.pageSizes.mediaInsideSize};
  text-align: center;
  margin-bottom: 20px;
`;

const Screenshots = styled.div`
  width: 100%;
  height: 600px;
  @media screen and (max-width: 1300px) {
    height: 500px;
  }
  @media screen and (max-width: 1000px) {
    height: 400px;
  }
  @media screen and (max-width: 768px) {
    height: 300px;
  }
  @media screen and (max-width: 500px) {
    height: 250px;
  }
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
`;
