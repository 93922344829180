import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";
import AboutCard from "../AboutComponents/about.card.component";
import ServicesCard from "../servicesComponents/Services.card.component";
import ServiceCard from "../newServices/services.card";

const CardWrapperComponent = ({
  cardData,
  title,
  header1,
  header2,
  card,
  buttonColor,
  page,
}) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [scrollMax, setScrollMax] = useState(0);

  const handleScroll = (e) => {
    const cardWrapper = e.target;
    setScrollPosition(cardWrapper.scrollLeft);
  };

  useEffect(() => {
    const cardBox = document.getElementById("card-box");
    if (cardBox) {
      setScrollMax(cardBox.scrollWidth - cardBox.clientWidth);
    }
  }, []);

  const scrollLeft = () => {
    const cardScrollContainer = document.getElementById(
      "card-scroll-container"
    );
    cardScrollContainer.scrollBy({ left: -250, behavior: "smooth" });
  };

  const scrollRight = () => {
    const cardScrollContainer = document.getElementById(
      "card-scroll-container"
    );
    cardScrollContainer.scrollBy({ left: 250, behavior: "smooth" });
  };

  return (
    <MainWrapper bg={card === "services"} card={card}>
      <TeamWrapper>
        <Header bg={card === "services"}>
          {header1} <br /> {header2}
          {card === "services" && (
            <>
              <LeftArrow
                bg={card === "services"}
                onClick={scrollLeft}
                buttonColor={buttonColor}
                page={page}
              />
              <RightArrow
                bg={card === "services"}
                onClick={scrollRight}
                buttonColor={buttonColor}
                page={page}
              />
            </>
          )}
        </Header>

        <CardWrapper id="card-wrapper">
          <CardScrollContainer id="card-scroll-container">
            <CardBox id="card-box">
              {cardData &&
                cardData.map((data, idx) =>
                  card === "about" ? (
                    <AboutCard key={idx} data={data} />
                  ) : card === "services" ? (
                    <ServiceCard key={idx} data={data} />
                  ) : (
                    <ServicesCard key={idx} data={data} />
                  )
                )}
            </CardBox>
          </CardScrollContainer>
        </CardWrapper>
      </TeamWrapper>
    </MainWrapper>
  );
};

export default CardWrapperComponent;

const MainWrapper = styled.div`
  width: 100%;
  ${"" /* height: ${({ bg }) => (bg ? "400px" : "600px")}; */}
  padding-top: 20px;
  ${"" /* ${({ theme }) => theme.pageSizes.mediaInsideSize} */}

  ${"" /* width: ${({ card }) => (card === "services" ? "900px" : "100%")}; */}

  ${({ theme }) => theme.flexing.columnCenter};
`;

const TeamWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const Title = styled.div`
  flex: 0.1;
  width: 100%;
  font-size: ${({ theme }) => theme.fontSizes.title};
  color: ${({ theme }) => theme.colors.text.secondary};
`;

const Header = styled.div`
  font-size: ${({ theme, bg }) =>
    bg ? theme.fontSizes.title : theme.fontSizes.h3};
  flex: ${({ bg }) => (bg ? 0.1 : 0.2)};
  color: ${({ theme }) => theme.colors.text.inverse};
  position: relative;
  padding: 20px 0 20px 50px;

  @media screen and (max-width: 768px) {
    font-size: ${({ theme, bg }) =>
      bg ? theme.fontSizes.title : theme.fontSizes.h4};
    text-align: center;
    padding-left: 0;
  }
`;

const CardWrapper = styled.div`
  flex: 0.4;
  display: flex;
  width: 100%;
  align-items: center;
  /* padding: 10px; */
`;

const CardScrollContainer = styled.div`
  width: 100vw;
  height: 100%;
  overflow-x: scroll;
  display: flex;
`;

const CardBox = styled.div`
  display: flex;
  width: fit-content;
  flex-shrink: 0;
`;

const LeftArrow = styled(BsArrowLeft)`
  position: absolute;
  top: 10px;
  right: 100px;
  color: ${({ theme }) => theme.colors.brand.inverse};
  font-size: 30px;
  border: 1px solid ${({ theme }) => theme.colors.brand.inverse};
  background: ${({ theme, bg }) => bg && theme.colors.ui.inverse};
  border-radius: 7px;
  padding: 0 15px;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.colors.ui.button};
    color: ${({ theme }) => theme.colors.brand.inverse};
  }
  @media screen and (max-width: 500px) {
    display: none;
  }
`;

const RightArrow = styled(BsArrowRight)`
  position: absolute;
  top: 10px;
  right: 20px;
  color: ${({ theme }) => theme.colors.brand.inverse};
  font-size: 30px;
  border: 1px solid ${({ theme }) => theme.colors.brand.inverse};
  background: ${({ theme, bg }) => bg && theme.colors.ui.inverse};
  border-radius: 7px;
  padding: 0 15px;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.colors.ui.button};
    color: ${({ theme }) => theme.colors.brand.inverse};
  }
  @media screen and (max-width: 500px) {
    display: none;
  }
`;
