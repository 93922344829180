import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { specializationData } from "../components/homeComponents/Specialization.data";
import ServiceDetailed from "../components/serviceDetailedComponent/Service.detailed";

const ServiceDetailedPage = () => {
  const { projectId } = useParams();

  const [data, setData] = useState(null);

  useEffect(() => {
    // Find the object in teamData with the matching id
    const specialization = specializationData.find(
      (service) => service.id === Number(projectId)
    );

    if (specialization) {
      setData(specialization);
    }
  }, [projectId]);
  return (
    <ServiceDetailedWrapper>
      <ServiceInsideWrapper>
        <ServiceDetailed />
      </ServiceInsideWrapper>
    </ServiceDetailedWrapper>
  );
};

export default ServiceDetailedPage;

const ServiceDetailedWrapper = styled.div`
  min-height: 600px;
  ${({ theme }) => theme.pageSizes.media};
`;

const ServiceInsideWrapper = styled.div`
  width: 100%;
  ${"" /* margin-top: 60px; */}
  min-height: 600px;
  background: ${({ theme }) => theme.colors.proposal.contact};
  ${({ theme }) => theme.flexing.columnCenter};
`;
