import React, { useState, useEffect } from "react";
import { styled } from "styled-components";
import { useNavigate } from "react-router";

const CookiesCustomize = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [showCustomizeModal, setShowCustomizeModal] = useState(false);
  const [personalization, setPersonalization] = useState(true);
  const [analytics, setAnalytics] = useState(true);
  const [learnMoreClicked, setLearnMoreClicked] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handlePopstate = () => {
      // Reset learnMoreClicked when navigating back
      setLearnMoreClicked(false);
    };

    window.addEventListener("popstate", handlePopstate);

    if (!localStorage.getItem("cookie-preferences") && !learnMoreClicked) {
      setShowPopup(true);
    } else {
      setShowPopup(false);
    }

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, [learnMoreClicked]);

  const acceptCookies = () => {
    localStorage.setItem("cookie-preferences", "accepted");
    loadGoogleAnalytics();
    setShowPopup(false);
  };

  const rejectCookies = () => {
    localStorage.setItem("cookie-preferences", "rejected");
    setShowPopup(false);
  };

  const openCustomizeModal = () => {
    setShowCustomizeModal(true);
  };

  const closeCustomizeModal = () => {
    setShowCustomizeModal(false);
  };

  const saveCustomPreferences = () => {
    {
      analytics
        ? localStorage.setItem("cookie-analytics", "accepted")
        : localStorage.setItem("cookie-analytics", "rejected");
    }
    {
      personalization
        ? localStorage.setItem("cookie-personalization", "accepted")
        : localStorage.setItem("cookie-personalization", "rejected");
    }
    setShowPopup(false);
    closeCustomizeModal();

    if (analytics && personalization) {
      loadGoogleAnalytics();
    }
  };

  const loadGoogleAnalytics = () => {
    // Load Google Analytics script or perform necessary initialization
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-MQEW41SN8Z";
    document.head.appendChild(script);

    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag("js", new Date());
      gtag("config", "G-MQEW41SN8Z");
    };
  };

  const handleLearnMoreClick = () => {
    // Set the state to indicate that the user has clicked "Learn more"
    setLearnMoreClicked(true);
    console.log(learnMoreClicked);
    // Navigate to the privacy page
    navigate("/privacyReadOnly");
  };

  return (
    <Main style={{ display: showPopup ? "flex" : "none" }}>
      <CookiesWrap
        style={{ display: showPopup && !showCustomizeModal ? "flex" : "none" }}
        className="cookie-popup"
      >
        <Text>
          This website uses cookies to ensure you get the best experience.
          Select your preferences:
        </Text>
        <ButtonWrap>
          <Button onClick={acceptCookies}>Accept All</Button>
          <Button onClick={rejectCookies}>Reject All</Button>
          <Button onClick={openCustomizeModal}>Customize</Button>
        </ButtonWrap>
        <LearnMoreWrap>
          <Text>We use cookies to enhance your user experience.</Text>
          <Link onClick={handleLearnMoreClick}>Learn more.</Link>
        </LearnMoreWrap>
      </CookiesWrap>

      <CookiesWrap
        style={{ display: showCustomizeModal ? "flex" : "none" }}
        className="customize-modal"
      >
        <Text>
          Take control of your data privacy. Select your tracking preferences
          for the best experience:
        </Text>
        <ButtonWrap>
          <Text>
            <input
              type="checkbox"
              checked={personalization}
              onChange={() => setPersonalization(!personalization)}
            />
            Personalisation (for a customised experience)
          </Text>

          <Text>
            <input
              type="checkbox"
              checked={analytics}
              onChange={() => setAnalytics(!analytics)}
            />
            Analytics (for a better website)
          </Text>
        </ButtonWrap>

        <Text>
          We use cookies to enable essential website operations and to ensure
          certain features work properly. These cookies don't store personally
          identifiable information.
        </Text>
        <ButtonWrap>
          <Button onClick={saveCustomPreferences}>Save Preferences</Button>
          <Button onClick={closeCustomizeModal}>Close</Button>
        </ButtonWrap>
      </CookiesWrap>
    </Main>
  );
};

export default CookiesCustomize;

const Main = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 99999;
  left: 0;
  bottom: 0;
  overflow: hidden;
  color: white;
  ${({ theme }) => theme.flexing.columnCenter};
`;

const CookiesWrap = styled.div`
  ${({ theme }) => theme.flexing.columnCenter};
  background: black;
  width: 90%;
  height: 300px;
  overflow: hidden;
  z-index: 999;

  @media screen and (max-width: 768px) {
    height: 500px;
  }
`;

const ButtonWrap = styled.div`
  ${({ theme }) => theme.flexing.flexCenter};

  @media screen and (max-width: 768px) {
    ${({ theme }) => theme.flexing.columnCenter};
  }
`;

const Button = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.button};
  width: 150px;
  text-align: center;
  background: white;
  color: black;
  margin: 20px;
  padding: 5px 20px;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.colors.ui.button};
  }
`;

const LearnMoreWrap = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const Text = styled.div`
  padding: 20px;
  font-size: ${({ theme }) => theme.fontSizes.button};
`;

const Link = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.button};
  text-decoration: underline;
  cursor: pointer;
`;
