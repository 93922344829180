import React from "react";
import isPropValid from "@emotion/is-prop-valid";
import { styled, StyleSheetManager } from "styled-components";
import CardHome from "./CardHome";
import { specializationData } from "./Specialization.data";

const HomeCardWrapper = () => {
  return (
    <StyleSheetManager shouldForwardProp={isPropValid}>
      <MainWrap>
        <InsideWrapper>
          <OurServices>Our field of specialization</OurServices>
          <ServicesDescr>
            Our commitment lies in providing the utmost excellence through our
            unmatched digital services, setting a benchmark for quality and
            innovation in the industry.
          </ServicesDescr>
          <CardsWrap>
            {specializationData.map((item, idx) => (
              <CardHome
                key={idx}
                service={item.service}
                logo={item.logo}
                text={item.text}
              />
            ))}
          </CardsWrap>
        </InsideWrapper>
      </MainWrap>
    </StyleSheetManager>
  );
};

export default HomeCardWrapper;

const MainWrap = styled.div`
  ${({ theme }) => theme.flexing.flexCenter};
  flex-wrap: wrap;
  background: ${({ theme }) => theme.colors.ui.secondary};
  width: 100%;
`;
const InsideWrapper = styled.div`
  ${({ theme }) => theme.pageSizes.mediaInsideSize};
  ${({ theme }) => theme.flexing.columnCenter};
  width: 100%;
  min-height: 600px;
  margin-left: auto;
  margin-right: auto;
`;

const CardsWrap = styled.div`
  ${({ theme }) => theme.flexing.flexCenter};
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 40px;
`;

const OurServices = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.body};
  width: 100%;
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-family: ${({ theme }) => theme.fonts.heading};
  text-align: left;
  margin: 40px 20px;

  @media screen and (max-width: 768px) {
    width: 95%;
  }
`;

const ServicesDescr = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.title};
  width: 90%;
  margin-bottom: 50px;

  @media screen and (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSizes.body};
    width: 85%;
  }
`;
