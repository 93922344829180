import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const ServicesCard = ({ data }) => {
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    if (videoRef.current && videoRef.current.paused) {
      videoRef.current.play().catch((error) => {
        console.log(error);
      });
    }
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
    }
    setIsHovered(false);
  };
  const handleNavigate = () => {
    navigate(`/project/${data.name}`);
  };
  return (
    <CardWrapper
      onClick={handleNavigate}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {data.video !== null && isHovered ? (
        <>
          <video
            ref={videoRef}
            src={data.video}
            autoPlay={false}
            loop
            muted
            loading="lazy"
          ></video>
          <DarkLayer />
        </>
      ) : (
        <ImgWrapper>
          <img src={data.img} alt={data.name} />
          <DarkLayer />
        </ImgWrapper>
      )}
      {data.logo ? <Logo src={data.logo} /> : <CardText>{data.name}</CardText>}
    </CardWrapper>
  );
};

export default ServicesCard;

const CardWrapper = styled.div`
  flex: 0 0 auto;
  position: relative;
  height: 350px;
  width: 250px;
  border: 1px solid ${({ theme }) => theme.colors.brand.inverse};
  border-radius: 7px;
  margin: 0 10px;
  cursor: pointer;
  overflow: hidden;
  scroll-snap-align: start;

  video {
    height: 100%;

    /* width: 100%; */
  }
  @media screen and (max-width: 768px) {
  }
`;

const ImgWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 100%;
    width: 100%;
  }
`;

const DarkLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const CardText = styled.div`
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-family: ${({ theme }) => theme.fonts.heading};
  font-size: ${({ theme }) => theme.fontSizes.title};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  text-transform: uppercase;
  text-align: center;
  z-index: 2;
`;
const Logo = styled.img`
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 50px;
  max-height: 50px;
`;
