import React from "react";
import styled from "styled-components";
import HomeCardWrapper from "../components/homeComponents/home.cardwrapper.component";
import CardProjectWrapper from "../components/projectComponents/CardProjectWrapper";
import HomeBackground from "../components/homeComponents/HomeBackground";
import HomeAboutUs from "../components/homeComponents/HomeAboutUs";

const Home = () => {
  return (
    <MainWrap>
      {/* <LoadingScreen /> */}
      <HomeBackground />
      <CardProjectWrapper />
      <HomeAboutUs />
      <HomeCardWrapper />
    </MainWrap>
  );
};

export default Home;

const MainWrap = styled.div`
  color: white;

  h3 {
    font-size: ${({ theme }) => theme.fontSizes.h3};
  }
  h5 {
    font-size: ${({ theme }) => theme.fontSizes.h5};
  }
`;
