import React from "react";
import styled from "styled-components";
import ContactComponent from "../components/contact/Contact.component";

const Contact = () => {
  return (
    <ContactWrapper>
      <ContactInsideWrapper>
        <ContactComponent />
      </ContactInsideWrapper>
    </ContactWrapper>
  );
};

export default Contact;

const ContactWrapper = styled.div`
  min-height: 600px;
  ${({ theme }) => theme.pageSizes.media};
`;

const ContactInsideWrapper = styled.div`
  width: 100%;
  min-height: 600px;
  /* background: #ebb354; */
  color: #fff;
  ${({ theme }) => theme.flexing.columnCenter};
  background: ${({ theme }) => theme.colors.proposal.contact};
`;
