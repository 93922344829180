import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { HiMiniArrowLongRight } from "react-icons/hi2";

const CardWork = ({ data }) => {
  const { title, background, projecttype } = data;
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(`/project/${title}`);
  };

  return (
    <CardContainer bg={background}>
      {/* <BlurOverlay /> */}
      <MainWrap onClick={handleNavigate}>
        <Info>
          <Title>{title}</Title>
          <ProjectType>{projecttype}</ProjectType>
          <AnchorProject>
            Go to project
            <ArrowContainer>
              <ArrowLine />

              <ArrowIcon />
            </ArrowContainer>
          </AnchorProject>
        </Info>
      </MainWrap>
    </CardContainer>
  );
};

export default CardWork;

const CardContainer = styled.div`
  position: relative;
  width: 350px;
  height: 300px;
  cursor: pointer;
  background: ${({ bg }) => `url(${bg})`};
  background-repeat: no-repeat;
  background-size: cover;

  &:hover::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(2px);
    transition: opacity 0.3s;
    opacity: 0;
  }

  &:hover::before {
    opacity: 1;
  }

  @media screen and (max-width: 768px) {
    height: 350px;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      backdrop-filter: blur(2px);
    }
  }
`;

const BlurOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(2px);
  background-color: rgba(0, 0, 0, 0.5);

  &:hover {
    backdrop-filter: blur(0px);
    background-color: none;
  }
`;

const MainWrap = styled.a`
  position: relative;
  ${({ theme }) => theme.flexing.flexCenter};
  width: 100%;
  height: 100%;
  color: ${({ theme }) => theme.colors.text.inverse};
  z-index: 1;

  @media screen and (max-width: 767px) {
    ${({ theme }) => theme.flexing.columnCenter};
    height: 350px;
    align-self: flex-start;
  }
`;

const Info = styled.div`
  ${({ theme }) => theme.flexing.columnCenter};
  position: relative;
  width: 100%;
  height: 100%;
  font-family: ${({ theme }) => theme.fonts.heading};
  opacity: 0;
  transition: opacity 0.3s;
  padding-left: 10%;

  &:hover {
    opacity: 1;
  }

  ${
    "" /* @media screen and (min-width: 1100px) {
    padding-left: 150px;
  } */
  }

  @media screen and (max-width: 500px) {
    padding-left: 20%;
    height: 40%;
    opacity: 1;
  }
`;

const Title = styled.div`
  width: 100%;
  flex: 0.1;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes.body};
`;

const ProjectType = styled.div`
  width: 100%;
  flex: 0.2;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  font-size: ${({ theme }) => theme.fontSizes.caption};
  font-family: ${({ theme }) => theme.fonts.heading};
  margin: 10px 0;
  color: rgb(206, 206, 206);
`;

const AnchorProject = styled.a`
  flex: 0.2;
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: ${({ theme }) => theme.fontSizes.button};
  font-family: ${({ theme }) => theme.fonts.heading};
  color: ${({ theme }) => theme.colors.brand.inverse};
  margin: 20px 0;
`;

const ArrowContainer = styled.div`
  display: flex;
  height: 20px;
  align-items: center;
  position: relative;
`;

const ArrowLine = styled.div`
  width: 80px;
  height: 2px;
  background-color: ${({ theme }) => theme.colors.brand.inverse};
  padding: 0;
`;

const ArrowHead = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.h4};
`;
const ArrowIcon = styled(HiMiniArrowLongRight)`
  font-size: 30px;
  position: absolute;
  left: 70px;
`;
